@import 'variables';
#main-slider{
	box-shadow: 1px 1px 1px 0 rgba(#000, 0.5)
}

div{
	&.atour{
		padding: 2px!important;
	}
	.popular-item{
		padding: 0!important;
		margin-bottom: 0px;
		transition:0.5s all ease;
		display: block;
		overflow: hidden;
		cursor: pointer;
		&.item-sm{
			.img{
				h3{
					font-size: 16px;
					margin-bottom: 5px;
					margin-left: 5px;
					padding: 3px 5px;
					small{

					}
				}
				.rating{
					font-size: 14px;
				}
			}
		}
		.over-layer{
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: auto;
			opacity: 0.7;
			background: rgba(255,255,255,0);
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(35%, rgba(255,255,255,0)), color-stop(57%, rgba(90,90,90,0)), color-stop(69%, rgba(0,0,0,0.28)), color-stop(100%, rgba(0,0,0,1)));
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
			background: -o-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
			background: -ms-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );	
			transition: 0.5s all ease;
		}
		.img{
			height: auto;
			overflow: hidden;
			padding: 0px!important;
			transition:0.5s all ease;
			.rating{
				position: absolute;
				top: 0;
				margin-top: 0px;
				width: 100%;
				display: block;
				text-align: left;
				padding: 3px 5px;
				font-size: 18px;
				color:#fff;
				opacity: 0.0;
				transition: 0.4s all ease;
				.stars{
					float: right;
					display: block;
					i{
						color:gold;
						font-size: 110%;
					}
				}
			}
			img{
				max-width: 100%;
				height: auto;
				display: block;
				filter: blur(0px);
				box-shadow: 1px 1px 2px 0 rgba(#000, 0.123);
				transition: 0.5s ease;
			}
			h3{
				z-index: 10;
				position: absolute;
				bottom: 0;
				margin-bottom: 10px;
				margin-left: 10px;
				left: 0;
				text-align: left;
				color:#fff;
				transition: 0.4s all ease;
				padding: 3px 10px;
				small{
					color:$yellow;
					font-size: 80%;
				}
			}
			p{
				padding: 5px;
				margin: 0 auto;
				opacity: 0.0;
				visibility: hidden;
				background-color: rgba(0,0,0,0.4);
				transition: 0.4s all ease;
			}
			.actions{
				position: absolute;
				bottom: 0;
				margin-bottom: 0;
				width: 100%;
				padding: 0 5px;
				display: block;
				min-height: 42px;
				opacity: 0.0;
				transition: 0.8s all ease;
			}
		}
		&:hover{
			box-shadow: 1px 0 15px 0 rgba(#000, 0.223);
			.img{
				img{
					filter: blur(1px);
					z-index: 1;
				}
				h3{
					margin-bottom: 85px;
					background-color: rgba(#000, 0.5);
					transition: 0.4s all ease;
					opacity: 0.0;
				}
				p{
					opacity: 1.0;			
					visibility: visible;
					transition: 0.4s all ease;

				}
				.rating{
					z-index: 2;
					opacity: 1.0;
				}
				.actions{
					z-index: 2;
					opacity: 1.0;
					transition: 0.8s all ease;
				}
			}
			.over-layer{
				opacity: 0;
				z-index: -10;
			} 
		}
	}
}
@media screen and (max-width: 1024px) {
	.popular-item{
		.img{
			h3{
				font-size: 18px;
				margin-bottom: 7px;
				margin-left: 7px;
				padding: 3px 7px;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.popular-item{
		max-width: 100vw!important;
		margin-right: 0px!important;
		.img{
			margin: 0px!important;
			h3{
				font-size: 16px;
				margin-bottom: 5px;
				margin-left: 5px;
				padding: 3px 5px;
			}
			.rating{
				font-size: 14px;
			}
		}
		&:hover{
			.img{
				img{
					filter: blur(0)!important;
				}
			}
		}
	}
}