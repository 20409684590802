@font-face {
  font-family: 'Raleway';
  font-style: normal;
  src: url("../fonts/Raleway-Regular.ttf"); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

@keyframes glow {
  0% {
    filter: brightness(100%) contrast(100%) grayscale(20%);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  50% {
    filter: brightness(130%) contrast(120%) grayscale(0%);
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.05); }
  100% {
    filter: brightness(100%) contrast(100%) grayscale(20%);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); } }

@keyframes glowD {
  0% {
    filter: brightness(100%) contrast(100%) grayscale(20%);
    box-shadow: 0 0 0 20px transparent; }
  50% {
    filter: brightness(130%) contrast(120%) grayscale(0%);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); }
  100% {
    filter: brightness(100%) contrast(100%) grayscale(20%);
    box-shadow: 0 0 0 20px transparent; } }

@keyframes borderS {
  0% {
    border-size: 0px; }
  50% {
    border-size: 3px; }
  100% {
    border-size: 25px; } }

.manage {
  list-style: none;
  margin: 0;
  padding: 0; }
  .manage > li {
    vertical-align: middle;
    line-height: 21px;
    font-size: 14px; }
    .manage > li > a {
      color: #444; }
      .manage > li > a > i {
        font-size: 18px;
        height: 21px;
        line-height: 21px;
        vertical-align: middle; }
    .manage > li > ul {
      list-style: none;
      display: none;
      height: auto;
      margin: 0;
      padding: 5px;
      position: absolute;
      width: auto;
      right: 0;
      margin-right: 0px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.231);
      border: 1px #dbdbdb solid;
      z-index: 50; }
      .manage > li > ul > li {
        height: 29px;
        padding: 3px 7px;
        width: 100%;
        vertical-align: middle;
        line-height: 25px;
        font-size: 14px;
        display: block;
        border-bottom: 1px #f7f7f7 solid; }
        .manage > li > ul > li > a {
          color: #444; }
          .manage > li > ul > li > a.red {
            color: #a02323; }
          .manage > li > ul > li > a > i {
            font-size: 18px;
            height: 21px;
            line-height: 21px;
            vertical-align: middle; }
        .manage > li > ul > li:hover {
          background-color: #f9f9f9; }
          .manage > li > ul > li:hover a {
            color: #7a1818;
            text-decoration: underline; }

.panel.narrow-panel {
  margin: 10px 30px; }

small.c-green, h1.c-green, h2.c-green, h3.c-green, h4.c-green, h5.c-green, h6.c-green, i.c-green, span.c-green, b.c-green, a.c-green, label.c-green, p.c-green, article.c-green, address.c-green, ul.c-green, ol.c-green, li.c-green, div.c-green, strong.c-green, tr.c-green, td.c-green, th.c-green {
  color: #20ab00 !important; }

small.c-orange, h1.c-orange, h2.c-orange, h3.c-orange, h4.c-orange, h5.c-orange, h6.c-orange, i.c-orange, span.c-orange, b.c-orange, a.c-orange, label.c-orange, p.c-orange, article.c-orange, address.c-orange, ul.c-orange, ol.c-orange, li.c-orange, div.c-orange, strong.c-orange, tr.c-orange, td.c-orange, th.c-orange {
  color: #1b98e0 !important; }

small.c-red, h1.c-red, h2.c-red, h3.c-red, h4.c-red, h5.c-red, h6.c-red, i.c-red, span.c-red, b.c-red, a.c-red, label.c-red, p.c-red, article.c-red, address.c-red, ul.c-red, ol.c-red, li.c-red, div.c-red, strong.c-red, tr.c-red, td.c-red, th.c-red {
  color: #a02323 !important; }

small.c-blue, h1.c-blue, h2.c-blue, h3.c-blue, h4.c-blue, h5.c-blue, h6.c-blue, i.c-blue, span.c-blue, b.c-blue, a.c-blue, label.c-blue, p.c-blue, article.c-blue, address.c-blue, ul.c-blue, ol.c-blue, li.c-blue, div.c-blue, strong.c-blue, tr.c-blue, td.c-blue, th.c-blue {
  color: #3586ff !important; }

small.c-cyan, h1.c-cyan, h2.c-cyan, h3.c-cyan, h4.c-cyan, h5.c-cyan, h6.c-cyan, i.c-cyan, span.c-cyan, b.c-cyan, a.c-cyan, label.c-cyan, p.c-cyan, article.c-cyan, address.c-cyan, ul.c-cyan, ol.c-cyan, li.c-cyan, div.c-cyan, strong.c-cyan, tr.c-cyan, td.c-cyan, th.c-cyan {
  color: #019ECB !important; }

small.c-violet, h1.c-violet, h2.c-violet, h3.c-violet, h4.c-violet, h5.c-violet, h6.c-violet, i.c-violet, span.c-violet, b.c-violet, a.c-violet, label.c-violet, p.c-violet, article.c-violet, address.c-violet, ul.c-violet, ol.c-violet, li.c-violet, div.c-violet, strong.c-violet, tr.c-violet, td.c-violet, th.c-violet {
  color: #B16DC8 !important; }

small.c-yellow, h1.c-yellow, h2.c-yellow, h3.c-yellow, h4.c-yellow, h5.c-yellow, h6.c-yellow, i.c-yellow, span.c-yellow, b.c-yellow, a.c-yellow, label.c-yellow, p.c-yellow, article.c-yellow, address.c-yellow, ul.c-yellow, ol.c-yellow, li.c-yellow, div.c-yellow, strong.c-yellow, tr.c-yellow, td.c-yellow, th.c-yellow {
  color: #e0c21b !important; }

small.c-dark, h1.c-dark, h2.c-dark, h3.c-dark, h4.c-dark, h5.c-dark, h6.c-dark, i.c-dark, span.c-dark, b.c-dark, a.c-dark, label.c-dark, p.c-dark, article.c-dark, address.c-dark, ul.c-dark, ol.c-dark, li.c-dark, div.c-dark, strong.c-dark, tr.c-dark, td.c-dark, th.c-dark {
  color: #6F6F6F !important; }

small.c-white, h1.c-white, h2.c-white, h3.c-white, h4.c-white, h5.c-white, h6.c-white, i.c-white, span.c-white, b.c-white, a.c-white, label.c-white, p.c-white, article.c-white, address.c-white, ul.c-white, ol.c-white, li.c-white, div.c-white, strong.c-white, tr.c-white, td.c-white, th.c-white {
  color: #fff !important; }

small.c-gray, h1.c-gray, h2.c-gray, h3.c-gray, h4.c-gray, h5.c-gray, h6.c-gray, i.c-gray, span.c-gray, b.c-gray, a.c-gray, label.c-gray, p.c-gray, article.c-gray, address.c-gray, ul.c-gray, ol.c-gray, li.c-gray, div.c-gray, strong.c-gray, tr.c-gray, td.c-gray, th.c-gray {
  color: #777 !important;
  cursor: default !important; }
  small.c-gray.pt, h1.c-gray.pt, h2.c-gray.pt, h3.c-gray.pt, h4.c-gray.pt, h5.c-gray.pt, h6.c-gray.pt, i.c-gray.pt, span.c-gray.pt, b.c-gray.pt, a.c-gray.pt, label.c-gray.pt, p.c-gray.pt, article.c-gray.pt, address.c-gray.pt, ul.c-gray.pt, ol.c-gray.pt, li.c-gray.pt, div.c-gray.pt, strong.c-gray.pt, tr.c-gray.pt, td.c-gray.pt, th.c-gray.pt {
    padding-top: 10px !important; }

.bordred {
  border: 1px #dbdbdb solid; }
  .bordred table {
    margin-bottom: 0px; }

.text-center {
  text-align: center; }

h1 {
  color: #7a1818;
  font-size: 2.2em; }

h2, h3, h4 {
  color: #2377a7; }
  h2.regualr, h3.regualr, h4.regualr {
    font-weight: 400; }

p {
  color: #666; }
  p.justify {
    text-align: justify;
    text-justify: distribute; }
    p.justify img {
      margin-bottom: 5px; }
  p.message-text {
    white-space: pre-line; }
  p.p-icon {
    line-height: 25px; }
    p.p-icon i {
      line-height: 20px;
      vertical-align: middle;
      height: 20px; }

i.green {
  color: #42c923; }

a {
  color: #a02323;
  text-decoration: none; }
  a.inbox {
    color: #666; }
    a.inbox:hover {
      color: #2377a7; }
    a.inbox.unread {
      font-weight: 600; }
  a.bt-rm {
    color: #888;
    cursor: pointer;
    transition: color 0.2s; }
    a.bt-rm i {
      cursor: pointer; }
    a.bt-rm:hover {
      color: #a02323; }
  a.bt-bm {
    color: #888;
    cursor: pointer;
    transition: color 0.2s; }
    a.bt-bm i {
      cursor: pointer; }
    a.bt-bm:hover {
      color: #3586ff; }
  a.bt-gm {
    color: #888;
    cursor: pointer;
    transition: color 0.2s; }
    a.bt-gm i {
      cursor: pointer; }
    a.bt-gm:hover {
      color: #42c923; }
  a.bt {
    cursor: pointer; }
  a:hover {
    color: #2377a7; }
  a.ta {
    color: #777; }
    a.ta img {
      max-height: 25px; }
    a.ta :hover {
      background-color: #efefef;
      color: #1bad87; }

h1, h2, h3, h4, h5, h6, i, span, label, p, article, address, ul, ol, li, div {
  cursor: default; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-size: 70%;
  color: #b5b5b5; }

h3 {
  margin-top: 10px;
  margin-bottom: 10px; }

.clickable {
  cursor: pointer !important; }

.bt {
  margin: 6px 0;
  padding: 6px 10px;
  text-decoration: none;
  transition: all 0.3s linear;
  text-align: center;
  display: inline-block;
  font-size: 16px; }
  .bt.bt-m-l {
    margin-left: 5px; }
  .bt.bt-icon {
    height: 30px;
    line-height: 30px;
    vertical-align: middle; }
  .bt.bti.i-right {
    padding-right: 35px !important; }
  .bt.bti.i-left {
    padding-left: 35px !important; }
  .bt i {
    cursor: pointer;
    padding: 12px 2px;
    top: 0; }
    .bt i.bt-icon-right {
      position: absolute;
      right: 0;
      margin-right: 20px; }
    .bt i.bt-icon-left {
      position: absolute;
      left: 0;
      margin-left: 20px; }
    .bt i.i-free {
      padding: 0;
      font-size: 100%;
      line-height: 20px;
      vertical-align: middle;
      height: 20px; }
  .bt.bt-lg {
    padding: 9px 11px;
    font-size: 18px; }
    .bt.bt-lg.bf {
      font-size: 125%; }
    .bt.bt-lg i {
      height: 25px;
      line-height: 24px;
      vertical-align: middle;
      font-size: 28px; }
  .bt.bt-xl {
    padding: 11px 13px;
    font-size: 19px;
    letter-spacing: 1px; }
  .bt.bt-sm {
    padding: 5px 8px;
    font-size: 13px; }
    .bt.bt-sm .i {
      line-height: 20px;
      vertical-align: middle;
      height: 20px; }
  .bt.bt-xs {
    padding: 3px 5px;
    font-size: 12px; }
    .bt.bt-xs .i {
      line-height: 20px;
      vertical-align: middle;
      height: 20px; }
  .bt.bt-block {
    display: block; }
  .bt.bt-default {
    border: 1px solid #fff;
    background-color: #fff;
    color: #555; }
    .bt.bt-default:hover {
      border: 1px solid #ababab;
      background-color: #fafafa;
      color: #333; }
    .bt.bt-default:active {
      background-color: #dbdbdb;
      color: #333; }
  .bt:hover {
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.175); }
  .bt.bt-green {
    background-color: #20ab00;
    border: 1px solid #20ab00;
    color: #fff; }
    .bt.bt-green:hover {
      background-color: transparent;
      color: #20ab00; }
    .bt.bt-green:active {
      background-color: #20ab00;
      color: #fff; }
  .bt.bt-red {
    background-color: #a02323;
    border: 1px solid #a02323;
    color: #fff; }
    .bt.bt-red:hover {
      background-color: transparent;
      color: #a02323; }
    .bt.bt-red:active {
      background-color: #a02323;
      color: #fff; }
  .bt.bt-blue {
    background-color: #3586ff;
    border: 1px solid #3586ff;
    color: #fff; }
    .bt.bt-blue:hover {
      background-color: transparent;
      color: #3586ff; }
    .bt.bt-blue:active {
      background-color: #3586ff;
      color: #fff; }
  .bt.bt-orange {
    background-color: #2377a7;
    border: 1px solid #2377a7;
    color: #fff; }
    .bt.bt-orange:hover {
      background-color: transparent;
      color: #2377a7; }
    .bt.bt-orange:active {
      background-color: #2377a7;
      color: #fff; }
  .bt.bt-yellow {
    background-color: #bfa61c;
    border: 1px solid #bfa61c;
    color: #fff; }
    .bt.bt-yellow:hover {
      background-color: transparent;
      color: #bfa61c; }
    .bt.bt-yellow:active {
      background-color: #bfa61c;
      color: #fff; }
  .bt.bt-cyan {
    background-color: #019ECB;
    border: 1px solid #019ECB;
    color: #fff; }
    .bt.bt-cyan:hover {
      background-color: transparent;
      color: #019ECB; }
    .bt.bt-cyan:active {
      background-color: #019ECB;
      color: #fff; }
  .bt.bt-violet {
    background-color: #B16DC8;
    border: 1px solid #B16DC8;
    color: #fff; }
    .bt.bt-violet:hover {
      background-color: transparent;
      color: #B16DC8; }
    .bt.bt-violet:active {
      background-color: #B16DC8;
      color: #fff; }
  .bt.bt-brown {
    background-color: #64402C;
    border: 1px solid #64402C;
    color: #fff; }
    .bt.bt-brown:hover {
      background-color: transparent;
      color: #64402C; }
    .bt.bt-brown:active {
      background-color: #64402C;
      color: #fff; }

.no-margain {
  margin: 0 !important; }

@media screen and (max-width: 992px) {
  .bt {
    margin: 6px 0;
    padding: 6px 10px;
    text-decoration: none;
    transition: all 0.3s linear;
    text-align: center;
    display: inline-block; }
    .bt.bti.i-right {
      padding-right: 35px !important; }
    .bt.bti.i-left {
      padding-left: 35px !important; }
    .bt i {
      padding: 6px 2px;
      top: 0;
      margin-top: 2px; }
      .bt i.bt-icon-right {
        position: absolute;
        right: 0;
        margin-right: 20px; }
      .bt i.bt-icon-left {
        position: absolute;
        left: 0;
        margin-left: 20px; }
  .panel.narrow-panel {
    margin: 10px 0px;
    margin-top: 0px; }
  .col-md-12 {
    padding: 5; }
  p {
    padding: 0 10px; }
  .npb {
    padding-bottom: 0px !important; }
  .npt {
    padding-top: 0px !important; } }

input.has-errors, select.has-errors, textarea.has-errors {
  border-color: #a02323 !important; }

a i {
  cursor: pointer; }

.carousel img {
  min-width: 100%; }

.sr-only {
  position: absolute;
  left: -9999999px; }

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto; }

.carousel {
  position: relative;
  z-index: 0; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left; }

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1; }

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px; }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block; }

.carousel-inner > .active {
  left: 0; }

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%; }

.carousel-inner > .next {
  left: 100%; }

.carousel-inner > .prev {
  left: -100%; }

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0; }

.carousel-inner > .active.left {
  left: -100%; }

.carousel-inner > .active.right {
  left: 100%; }

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7%;
  min-width: 50px;
  font-size: 20px;
  color: #1ba9fa;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=50);
  opacity: .5; }

.carousel-control.right {
  right: 0;
  left: auto; }

.carousel-control:hover,
.carousel-control:focus {
  color: #2377a7;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9; }

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  font-size: 30px;
  margin-top: -20px; }

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 0;
  margin-left: 0px; }

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 0;
  margin-right: 0px; }

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-weight: 700;
  line-height: 1; }

.carousel-control .icon-prev:before {
  content: '\2039'; }

.carousel-control .icon-next:before {
  content: '\203a'; }

.carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
  margin-right: 10px;
  z-index: 15;
  width: 60%;
  text-align: right;
  list-style: none; }

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #1b98e0;
  border-radius: 0; }

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #a02323; }

.carousel-indicators li:hover {
  width: 12px;
  height: 12px;
  background-color: #a02323; }

.carousel-price {
  display: none;
  text-align: center;
  position: absolute;
  top: 0;
  margin-top: 20px;
  left: 0;
  margin-left: 20px;
  font-style: italic;
  background-color: #20ab00;
  padding: 10px;
  font-size: 100%;
  line-height: 20px;
  box-shadow: 0 0 0 4px #e0c21b, 4px 6px 3px 0 rgba(0, 0, 0, 0.3);
  z-index: 1000; }
  .carousel-price span {
    color: #fff; }
  .carousel-price h4 {
    font-weight: bold;
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: gold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
    .carousel-price h4 small {
      color: #fff;
      font-size: 14px; }

.carousel-hot {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-style: italic;
  background-color: #7a1818;
  margin-bottom: 18%;
  margin-right: 14%;
  padding: 4px;
  font-size: 70%;
  line-height: 15px;
  box-shadow: 0 0 0 2px #1b98e0, -4px 6px 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  z-index: 1000; }
  .carousel-hot span {
    color: #fff; }
  .carousel-hot h4 {
    font-weight: bold;
    font-size: 22px;
    padding: 0;
    margin: 0;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
    .carousel-hot h4 small {
      color: #fff;
      font-size: 10px; }

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  padding: 5px;
  z-index: 140;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
  .carousel-caption h4 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    color: #fff; }
    .carousel-caption h4 small {
      color: gold;
      font-size: 14px; }
      .carousel-caption h4 small .bt {
        display: inline-block; }
  .carousel-caption p {
    display: none;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 15px;
    padding-top: 10px;
    margin-top: 0;
    font-style: italic;
    color: #cfcfcf; }
    .carousel-caption p:before {
      content: '"'; }
    .carousel-caption p:after {
      content: '"'; }
    .carousel-caption p a {
      color: gold; }
      .carousel-caption p a:hover {
        color: #cc2626; }

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 40px;
    height: 70px;
    margin-top: -35px;
    font-size: 70px; }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    left: 0;
    margin-left: 10px; }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: 30px;
    right: 0; }
  .carousel-caption {
    left: 0;
    padding-bottom: 10px; }
    .carousel-caption h4 {
      padding: 10px;
      font-size: 28px; }
      .carousel-caption h4 small {
        font-size: 18px; }
        .carousel-caption h4 small .bt {
          display: none; }
    .carousel-caption p {
      display: block;
      padding: 0 15px; }
  .carousel-price {
    display: block; }
  .carousel-hot {
    margin-bottom: 20%;
    margin-right: 10%;
    padding: 10px;
    font-size: 100%;
    line-height: 20px;
    box-shadow: 0 0 0 4px #1b98e0, -4px 6px 3px 0 rgba(0, 0, 0, 0.3); }
    .carousel-hot span {
      color: #fff; }
    .carousel-hot h4 {
      font-weight: bold;
      font-size: 30px;
      padding: 0;
      margin: 0;
      color: #fff;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
      .carousel-hot h4 small {
        color: #fff;
        font-size: 14px; }
  .carousel-indicators {
    bottom: 0; }
  .carousel-indicators li {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #1b98e0;
    border-radius: 0; }
  .carousel-indicators .active {
    width: 20px;
    height: 20px;
    margin: 0;
    background-color: #a02323; }
  .carousel-indicators li:hover {
    width: 20px;
    height: 20px;
    background-color: #a02323; } }

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0;
  color: #666; }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #dddddd; }

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 1px solid #dddddd; }

.table .table {
  background-color: #ffffff; }

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }

@media screen and (max-width: 767px) {
  th, td {
    font-size: 85%; }
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dddddd; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive > .table-bordered {
    border: 0; }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.btn-xs .badge,
.btn-group-xs > .btn .badge {
  top: 0;
  padding: 1px 5px; }

a.badge:hover,
a.badge:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer; }

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #ffffff; }

.list-group-item > .badge {
  float: right; }

.list-group-item > .badge + .badge {
  margin-right: 5px; }

.nav-pills > li > a > .badge {
  margin-left: 3px; }

.list-group {
  margin-bottom: 20px;
  padding-left: 0; }

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid #dddddd; }

.list-group-item:first-child {
  border-top-right-radius: 1px;
  border-top-left-radius: 1px; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px; }

a.list-group-item,
button.list-group-item {
  color: #555555; }

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333333; }

a.list-group-item:hover,
button.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:focus {
  text-decoration: none;
  color: #555555;
  background-color: #f5f5f5; }

button.list-group-item {
  width: 100%;
  text-align: left; }

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  background-color: #eeeeee;
  color: #777777;
  cursor: not-allowed; }

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
  color: inherit; }

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #777777; }

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #ffffff;
  background-color: #337ab7;
  border-color: #337ab7; }

.list-group-item.active .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit; }

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #c7ddef; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d; }

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit; }

a.list-group-item-success:hover,
button.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6; }

a.list-group-item-success.active,
button.list-group-item-success.active,
a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f; }

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit; }

a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3; }

a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b; }

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit; }

a.list-group-item-warning:hover,
button.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc; }

a.list-group-item-warning.active,
button.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442; }

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit; }

a.list-group-item-danger:hover,
button.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc; }

a.list-group-item-danger.active,
button.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442; }

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.row .col-md-6:nth-child(1) {
  padding-bottom: 10px; }

@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .row .col-md-6:nth-child(1) {
    padding-right: 6px;
    padding-bottom: 0; }
  .row .col-md-6:nth-child(2) {
    padding-left: 6px; } }

@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0px;
  -webkit-transition: border-color ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s;
  transition: border-color ease-in-out .15s; }

.form-control:focus {
  border-color: #3586ff;
  outline: 0; }

.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #999999; }

.form-control::-webkit-input-placeholder {
  color: #999999; }

.form-control::-ms-expand {
  border: 0;
  background-color: transparent; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1; }

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 1px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 1px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 2px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 2px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }

.has-feedback .form-control {
  padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #20ab00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus {
  border-color: #20ab00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #42c923;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #42c923; }

.has-success .input-group-addon {
  color: #20ab00;
  border-color: #42c923;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #2377a7;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus {
  border-color: #2377a7;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #1b98e0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #1b98e0; }

.has-warning .input-group-addon {
  color: #2377a7;
  border-color: #1b98e0;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #64402C; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #7a1818; }

.has-error .form-control {
  border-color: #a02323; }

.has-error .form-control:focus {
  border-color: #7a1818;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #a02323;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #a02323; }

.has-error .input-group-addon {
  color: #a02323;
  border-color: #7a1818;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #7a1818; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.clearfix:before,
.clearfix:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  display: table; }

.clearfix:after,
.form-horizontal .form-group:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

.panel {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px; }

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit; }

.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0; }

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px; }

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }

.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px; }

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 0px; }

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 0px; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; }

.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 0px; }

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 0px; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #dddddd; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0; }

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0; }

.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0; }

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 20px; }

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 1px; }

.panel-group .panel + .panel {
  margin-top: 5px; }

.panel-group .panel-heading {
  border-bottom: 0; }

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #dddddd; }

.panel-group .panel-footer {
  border-top: 0; }

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #dddddd; }

.panel-default {
  border-color: #dddddd; }

.panel-default > .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #dddddd; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dddddd; }

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333; }

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dddddd; }

.panel-primary {
  border-color: #337ab7; }

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #337ab7; }

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7; }

.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #ffffff; }

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7; }

.panel-success {
  border-color: #d6e9c6; }

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6; }

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d; }

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1; }

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f; }

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc; }

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b; }

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1; }

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442; }

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1; }

.clearfix:before,
.clearfix:after,
.panel-body:before,
.panel-body:after {
  content: " ";
  display: table; }

.clearfix:after,
.panel-body:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

.styled-checkbox {
  position: absolute;
  opacity: 0; }
  .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: "Verdana", sans-serif;
    font-weight: 300;
    font-size: 100%; }
    .styled-checkbox + label:hover {
      color: #20ab00; }
    .styled-checkbox + label i {
      line-height: 30px;
      vertical-align: middle; }
  .styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.22);
    transition: 0.2s all; }
  .styled-checkbox:hover + label:before {
    background: #6cca56;
    box-shadow: 0 0 0 1px #20ab00; }
  .styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.32);
    transition: 0.2s all; }
  .styled-checkbox:checked + label:before {
    background: #fff; }
  .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd; }
  .styled-checkbox:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: #20ab00;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #20ab00, 4px 0 0 #20ab00, 4px -2px 0 #20ab00, 4px -4px 0 #20ab00, 4px -6px 0 #20ab00, 4px -8px 0 #20ab00;
    transform: rotate(45deg); }

html {
  padding: 0;
  margin: 0;
  height: auto;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Raleway", sans-serif; }
  html body {
    background-color: #f7f7f7;
    font-family: "Raleway", sans-serif;
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    max-width: 100vw;
    display: block;
    text-align: center; }
    html body.admin {
      display: table !important;
      padding-top: 80px !important;
      text-align: left;
      min-height: 100vh !important; }

.header {
  transition: height 0.2s ease;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 110px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.203);
  z-index: 10000; }
  .header.admin {
    height: 60px; }
    .header.admin .logo img {
      padding: 0px !important;
      max-height: 50px;
      margin-top: 7px;
      width: auto !important; }
    .header.admin nav {
      margin-top: 10px !important; }
      .header.admin nav ul li:hover > ul {
        visibility: visible;
        opacity: 1.0;
        margin-top: -7px !important; }
  .header .h-wrapper {
    height: 100%;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto; }
    .header .h-wrapper > .settings {
      height: 20px;
      width: 100%; }
      .header .h-wrapper > .settings > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: block;
        float: right;
        z-index: 10; }
        .header .h-wrapper > .settings > ul > li {
          float: right;
          padding: 0 5px 0 0;
          transition: all 0.3s ease; }
          .header .h-wrapper > .settings > ul > li > a {
            font-size: 70%;
            padding: 5px 10px 5px 20px;
            display: inline-block;
            color: #777;
            text-decoration: none;
            transition: color 0.2s liner; }
            .header .h-wrapper > .settings > ul > li > a > i {
              font-size: 15px;
              position: absolute; }
              .header .h-wrapper > .settings > ul > li > a > i:nth-child(1) {
                margin-left: -20px; }
              .header .h-wrapper > .settings > ul > li > a > i:nth-child(2) {
                margin-left: 11px;
                margin-top: -13px; }
            .header .h-wrapper > .settings > ul > li > a:hover {
              color: #cc2626; }
            .header .h-wrapper > .settings > ul > li > a:active {
              color: #a02323; }
          .header .h-wrapper > .settings > ul > li > ul {
            list-style: none;
            margin: 0;
            padding: 3px;
            background-color: #fafafa;
            box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.123);
            position: absolute;
            width: 100px;
            top: 0;
            margin-top: 20px;
            transition: all 0.3s ease-in-out;
            visibility: hidden;
            opacity: 0.0; }
            .header .h-wrapper > .settings > ul > li > ul.lg {
              margin-left: -60px; }
            .header .h-wrapper > .settings > ul > li > ul > li {
              padding: 0 5px;
              text-align: center;
              vertical-align: middle;
              min-height: 20px;
              line-height: 20px; }
              .header .h-wrapper > .settings > ul > li > ul > li > a {
                display: block;
                text-decoration: none;
                font-size: 80%;
                min-height: 20px;
                color: #666;
                font-weight: normal;
                transition: color 0.2s linear; }
              .header .h-wrapper > .settings > ul > li > ul > li > i {
                display: block;
                cursor: default;
                color: #999;
                transition: color 0.2s linear; }
              .header .h-wrapper > .settings > ul > li > ul > li.active {
                background-color: rgba(0, 0, 0, 0.03); }
                .header .h-wrapper > .settings > ul > li > ul > li.active > a {
                  cursor: default;
                  color: #999;
                  text-decoration: underline; }
                .header .h-wrapper > .settings > ul > li > ul > li.active:hover a {
                  color: #999; }
              .header .h-wrapper > .settings > ul > li > ul > li:hover > a {
                color: #7a1818;
                text-decoration: underline; }
              .header .h-wrapper > .settings > ul > li > ul > li:hover > i {
                color: #666; }
          .header .h-wrapper > .settings > ul > li:hover > a {
            color: #7a1818; }
          .header .h-wrapper > .settings > ul > li:hover > i {
            color: #999; }
          .header .h-wrapper > .settings > ul > li:hover > ul {
            visibility: visible;
            opacity: 1.0;
            margin-top: 20px; }
    .header .h-wrapper .logo {
      height: auto;
      display: block;
      padding: 0;
      float: left;
      min-width: 40%;
      max-width: 250px;
      transition: 0.5s all; }
      .header .h-wrapper .logo a {
        display: block;
        padding: 0;
        margin: 0; }
        .header .h-wrapper .logo a img {
          transition: 0.5s all;
          padding: 10px;
          max-width: calc(100% - 20px);
          width: 300px;
          float: left;
          min-width: 150px; }
    .header .h-wrapper .menu {
      height: 90px;
      max-width: 58%;
      float: right; }
      .header .h-wrapper .menu > .toggle {
        display: none;
        position: absolute;
        right: 0;
        margin-right: 5px;
        bottom: 0;
        margin-bottom: 0;
        padding: 0;
        cursor: pointer;
        width: 77px;
        line-height: 30px;
        vertical-align: middle;
        height: 30px;
        color: #666; }
        .header .h-wrapper .menu > .toggle > span {
          z-index: -1;
          cursor: pointer;
          position: absolute;
          left: 0;
          margin-top: 0px; }
        .header .h-wrapper .menu > .toggle > i {
          z-index: -1;
          font-size: 170%;
          cursor: pointer;
          position: absolute;
          right: 0; }
          .header .h-wrapper .menu > .toggle > i.close {
            display: none;
            color: #7a1818; }
      .header .h-wrapper .menu > nav {
        height: 60px;
        margin-top: 30px;
        vertical-align: text-bottom;
        transition: margin 0.2s ease; }
        .header .h-wrapper .menu > nav > ul {
          list-style: none;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%; }
          .header .h-wrapper .menu > nav > ul > li {
            float: left;
            padding: 10px 15px;
            text-align: center; }
            .header .h-wrapper .menu > nav > ul > li.icon-only {
              padding: 10px 5px; }
              .header .h-wrapper .menu > nav > ul > li.icon-only > a > i {
                cursor: pointer;
                margin-top: -13px; }
              .header .h-wrapper .menu > nav > ul > li.icon-only > a span {
                display: none; }
              .header .h-wrapper .menu > nav > ul > li.icon-only > a label {
                position: absolute;
                margin-top: -12px;
                margin-left: 29px;
                font-size: 12px;
                font-family: "Arial", sans-serif;
                font-weight: 600;
                color: #fff;
                padding: 1px 4.5px;
                z-index: -1; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > a label.green {
                  background-color: #42c923; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > a label.orange {
                  background-color: #1b98e0; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > a label.red {
                  background-color: #a02323; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > a label.blue {
                  background-color: #3586ff; }
              .header .h-wrapper .menu > nav > ul > li.icon-only > i {
                margin-top: -7px; }
              .header .h-wrapper .menu > nav > ul > li.icon-only > ul > li {
                text-align: left;
                cursor: pointer; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > ul > li > a {
                  display: inline-block; }
                .header .h-wrapper .menu > nav > ul > li.icon-only > ul > li > i.addon {
                  position: absolute;
                  display: inline-block;
                  right: 0;
                  width: 20px;
                  margin-top: 0px;
                  margin-right: 3px;
                  font-size: 110%; }
                  .header .h-wrapper .menu > nav > ul > li.icon-only > ul > li > i.addon.count {
                    width: 25px;
                    padding: 2px 0;
                    background-color: #bababa;
                    color: #fff;
                    text-align: center;
                    font-family: "Arial", sans-serif;
                    font-style: normal;
                    font-size: 75%;
                    margin-top: 0px; }
            .header .h-wrapper .menu > nav > ul > li.active > a {
              color: #7a1818; }
            .header .h-wrapper .menu > nav > ul > li > a {
              display: block;
              text-decoration: none;
              font-size: 120%;
              color: #666;
              font-weight: normal;
              transition: color 0.2s linear; }
              .header .h-wrapper .menu > nav > ul > li > a > i {
                border: 1px transparent solid;
                padding: 10px; }
            .header .h-wrapper .menu > nav > ul > li > i {
              display: block;
              cursor: default;
              color: transparent;
              transition: color 0.5s linear; }
            .header .h-wrapper .menu > nav > ul > li:hover > a {
              color: #7a1818; }
              .header .h-wrapper .menu > nav > ul > li:hover > a > i {
                border: 1px #dbdbdb solid; }
              .header .h-wrapper .menu > nav > ul > li:hover > a:hover label {
                z-index: 100; }
            .header .h-wrapper .menu > nav > ul > li:hover > i {
              color: #999; }
            .header .h-wrapper .menu > nav > ul > li:hover > ul {
              visibility: visible;
              opacity: 1.0;
              margin-top: 0px; }
            .header .h-wrapper .menu > nav > ul > li > ul {
              list-style: none;
              margin: 0;
              padding: 5px;
              background-color: #fafafa;
              box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.123);
              position: absolute;
              width: 175px;
              margin-left: -60px;
              margin-top: 20px;
              transition: all 0.3s ease-in-out;
              visibility: hidden;
              opacity: 0.0; }
              .header .h-wrapper .menu > nav > ul > li > ul.lg {
                margin-left: -30px; }
              .header .h-wrapper .menu > nav > ul > li > ul.sg {
                margin-left: -45px; }
              .header .h-wrapper .menu > nav > ul > li > ul > li {
                padding: 5px 5px;
                text-align: center;
                cursor: pointer; }
                .header .h-wrapper .menu > nav > ul > li > ul > li > a {
                  display: block;
                  text-decoration: none;
                  font-size: 95%;
                  min-height: 20px;
                  color: #666;
                  font-weight: normal;
                  transition: color 0.2s linear; }
                .header .h-wrapper .menu > nav > ul > li > ul > li > i {
                  display: block;
                  cursor: pointer;
                  color: #999;
                  transition: color 0.2s linear; }
                .header .h-wrapper .menu > nav > ul > li > ul > li > ul {
                  list-style: none;
                  margin: 0;
                  padding: 5px;
                  background-color: #fafafa;
                  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.123);
                  width: auto;
                  min-width: 140px;
                  margin-left: -150px;
                  position: absolute;
                  top: 0;
                  margin-top: 0px;
                  transition: all 0.3s ease-in-out;
                  visibility: hidden;
                  opacity: 0.0; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li > ul.lg {
                    margin-left: -60px; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li {
                    padding: 5px 5px;
                    text-align: center; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li > a {
                      display: block;
                      text-decoration: none;
                      font-size: 95%;
                      height: 20px;
                      color: #666;
                      font-weight: normal;
                      transition: color 0.2s linear; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li > i {
                      display: block;
                      cursor: default;
                      color: #999;
                      transition: color 0.2s linear; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li:hover > a {
                      color: #7a1818;
                      text-decoration: underline; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li:hover > i.addon {
                      color: #a02323; }
                      .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li:hover > i.addon.count {
                        background-color: #a02323; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li > ul > li:hover > i {
                      color: #666; }
                .header .h-wrapper .menu > nav > ul > li > ul > li:hover {
                  background-color: rgba(0, 0, 0, 0.03); }
                  .header .h-wrapper .menu > nav > ul > li > ul > li:hover > a {
                    color: #7a1818;
                    text-decoration: underline; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li:hover > i.addon {
                    color: #a02323; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li:hover > i.addon.count {
                      background-color: #a02323; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li:hover > i {
                    color: #666; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li:hover > ul {
                    visibility: visible;
                    opacity: 1.0; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.title {
                  background-color: rgba(255, 255, 255, 0.7);
                  text-align: center; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.title.has-img {
                    min-height: 43px !important; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.title > .settings {
                    position: absolute;
                    right: 0;
                    font-size: 100%;
                    margin-right: 55px;
                    margin-top: 10px;
                    color: #999;
                    cursor: pointer; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li.title > .settings:hover {
                      color: #a02323; }
                    .header .h-wrapper .menu > nav > ul > li > ul > li.title > .settings:active {
                      color: #7a1818; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.title > img {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 5px; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.title > span {
                    color: #888;
                    cursor: default;
                    font-size: 90%;
                    display: block; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.checkout > i {
                  color: #42c923; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.checkout > i.addon {
                    font-size: 24px;
                    width: 30px;
                    margin-top: -2px;
                    margin-right: 4px; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.checkout:hover > a {
                  color: #20ab00; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.checkout:hover > i.addon {
                  color: #20ab00; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.active {
                  background-color: rgba(0, 0, 0, 0.05); }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.active > a {
                    cursor: default;
                    color: #7a1818; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.active:hover a {
                    text-decoration: none; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.separator {
                  height: 0px;
                  max-height: 0px;
                  padding: 0;
                  margin: 0 2px;
                  border-bottom: 1px solid #efefef; }
  .header.mini {
    height: 60px; }
    .header.mini .h-wrapper > .settings {
      display: none; }
    .header.mini .h-wrapper > .menu {
      height: 60px; }
      .header.mini .h-wrapper > .menu > nav {
        margin-top: 0; }
        .header.mini .h-wrapper > .menu > nav > ul {
          height: 100%; }
          .header.mini .h-wrapper > .menu > nav > ul > li {
            padding-bottom: 0px !important;
            height: calc(100%); }
            .header.mini .h-wrapper > .menu > nav > ul > li > a {
              margin-top: 5px; }
            .header.mini .h-wrapper > .menu > nav > ul > li > ul {
              margin-top: -7px; }
    .header.mini .h-wrapper > .logo img {
      padding: 5px; }

#tc-ul {
  width: 290px;
  margin-left: -125px; }

.mr {
  margin-right: 5px !important; }

.paggination {
  margin-top: 5px;
  padding: 0px;
  text-align: center;
  height: 20px;
  vertical-align: middle;
  line-height: 20px;
  color: #777; }
  .paggination a {
    color: #3586ff; }
    .paggination a i {
      height: 20px;
      vertical-align: middle;
      line-height: 18px; }

#search .empty-message {
  padding: 5px 10px;
  text-align: center; }

@keyframes pulse {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.blocker {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: -10;
  visibility: hidden;
  opacity: 0.0;
  transition: 0.3s opacity ease; }
  .blocker.active {
    z-index: 9999 !important;
    visibility: visible;
    opacity: 1.0; }
  .blocker .ldr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    width: 2.5em;
    height: 2.5em; }
    .blocker .ldr span {
      position: absolute;
      margin-top: 2.75em;
      font-weight: 300;
      font-size: 14px;
      color: #666; }
    .blocker .ldr .ldr-blk {
      height: 35%;
      width: 35%;
      animation: pulse 0.75s ease-in infinite alternate;
      background-color: #2377a7; }
      .blocker .ldr .ldr-blk.an_delay {
        animation-delay: 0.75s; }

.twitter-typeahead {
  display: block !important; }
  .twitter-typeahead .tt-menu {
    box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.11);
    width: 100%;
    background-color: #fff; }
    .twitter-typeahead .tt-menu .tt-suggestion {
      text-align: left;
      padding: 10px 10px; }
      .twitter-typeahead .tt-menu .tt-suggestion.tt-cursor {
        background-color: #1b98e0;
        color: #fff; }

#modal-blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.756);
  top: 0;
  left: 0;
  z-index: 150;
  visibility: visible;
  opacity: 1.0;
  transition: 0.2s opacity ease-in;
  z-index: 10001; }
  #modal-blocker.closed {
    opacity: 0.0;
    visibility: hidden;
    transition: 0.3s opacity ease-out;
    z-index: 149; }
    #modal-blocker.closed #modal {
      transition: 0.3s top ease-out;
      top: -20%; }

#filter-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 300px !important;
  background-color: rgba(255, 255, 255, 0.756);
  top: 0;
  left: 0;
  z-index: 150;
  visibility: visible;
  opacity: 1.0;
  transition: 0.2s opacity ease-in;
  z-index: 10001; }
  #filter-blocker.closed {
    opacity: 0.0;
    visibility: hidden;
    transition: 0.3s opacity ease-out;
    z-index: 149; }

#modal {
  position: absolute;
  height: auto;
  width: 500px;
  min-height: 150px;
  max-width: 100%;
  max-height: 100vh;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  background-color: #fff;
  border-radius: 1px;
  border: 1px rgba(0, 0, 0, 0.153) solid;
  box-shadow: 1px 2px 14px 0 rgba(0, 0, 0, 0.223);
  transition: 0.5s top ease; }
  #modal #modal-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    opacity: 0.0;
    transition: 0.5s opacity ease-in; }
    #modal #modal-content.active {
      opacity: 1.0;
      transition: 0.3s opacity ease-out; }
  #modal.modal-md {
    width: 800px; }
  #modal .modal-header {
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
    height: 68px; }
    #modal .modal-header .modal-title {
      display: inline-block; }
      #modal .modal-header .modal-title h4 {
        font-size: 24px;
        color: #2377a7;
        margin: 0;
        padding: 15px 0 5px 0; }
        #modal .modal-header .modal-title h4 small {
          color: #ababab;
          font-size: 70%;
          font-weight: 400; }
    #modal .modal-header .modal-close {
      display: block;
      float: right;
      color: #666;
      cursor: pointer;
      font-size: 80%;
      height: 20px;
      line-height: 10px;
      text-align: text-top; }
      #modal .modal-header .modal-close span {
        position: absolute;
        margin-right: 5px;
        right: 0;
        cursor: pointer;
        z-index: 10000 !important; }
      #modal .modal-header .modal-close:hover {
        color: #a02323; }
  #modal .modal-body {
    height: auto;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 140px); }
  #modal .modal-footer {
    padding: 10px;
    display: block;
    height: auto;
    min-height: 55px; }
    #modal .modal-footer .modal-yes {
      float: right;
      display: block;
      margin-left: 5px; }
    #modal .modal-footer .modal-no {
      float: right; }

.content-placeholder {
  transition: padding 0.2s ease;
  height: 100%;
  margin: 0 auto;
  padding: 120px 0 20px;
  width: 1080px;
  min-height: calc(100vh - 139px);
  max-width: 100%;
  display: inline-block; }
  .content-placeholder.mini {
    padding-top: 60px; }
  .content-placeholder.admin {
    display: table-row !important;
    height: 100% !important; }

.footer {
  width: 100%;
  max-width: 100vw;
  height: auto;
  background-color: #e7e7e7;
  margin: 0;
  display: block; }
  .footer.admin {
    display: table-row !important;
    text-align: center; }
  .footer .container-fluid {
    padding-top: 15px;
    padding-bottom: 15px; }
    .footer .container-fluid .logo img {
      filter: grayscale(100%) brightness(105%) contrast(90%);
      transition: 0.2s;
      max-height: 100px;
      min-height: 100px; }
    .footer .container-fluid .logo:hover img {
      filter: grayscale(0%) brightness(100%) contrast(100%); }
    .footer .container-fluid .social {
      text-align: right;
      display: block; }
    .footer .container-fluid .fsocial {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0; }
    .footer .container-fluid .fsocial > li {
      float: left;
      width: auto;
      padding: 3px;
      border-radius: 50%;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.3); }
    .footer .container-fluid .fsocial > li > a > img {
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      font-size: 110%;
      filter: grayscale(100%) brightness(105%) contrast(90%);
      transition: 0.2s; }
      .footer .container-fluid .fsocial > li > a > img:hover {
        filter: grayscale(0%) brightness(100%) contrast(100%); }
    .footer .container-fluid .quick-links {
      list-style: none;
      text-align: center;
      margin: 10px 0;
      padding: 0;
      display: block; }
      .footer .container-fluid .quick-links li {
        text-align: center;
        margin: 5px 0; }
        .footer .container-fluid .quick-links li a {
          color: #666;
          padding: 10px 5px;
          margin: 5px;
          font-size: 14px;
          transition: color 0.2s; }
          .footer .container-fluid .quick-links li a img {
            filter: grayscale(100%) brightness(105%) contrast(90%);
            transition: 0.2s;
            max-height: 30px;
            margin-top: 3px; }
          .footer .container-fluid .quick-links li a:hover {
            color: #a02323; }
            .footer .container-fluid .quick-links li a:hover img {
              filter: grayscale(0%) brightness(100%) contrast(100%); }

.dd-icon {
  font-size: 16px !important;
  display: inline-block;
  line-height: 21px;
  vertical-align: middle; }

.s-icon {
  position: absolute;
  display: block;
  right: 0;
  margin-right: 10px;
  top: 0;
  margin-top: 7px;
  padding-top: 5px;
  background: transparent;
  border: none;
  color: #777;
  transition: 0.3s all linear;
  outline: none;
  cursor: pointer;
  border-radius: 50%; }
  .s-icon i {
    cursor: pointer; }
  .s-icon:disabled {
    color: #e1e1e1;
    cursor: default; }
    .s-icon:disabled:hover, .s-icon:disabled:focus {
      color: #e1e1e1;
      text-shadow: none;
      animation-name: none; }
    .s-icon:disabled i {
      cursor: default; }
  .s-icon:hover, .s-icon:focus {
    color: #3586ff;
    text-shadow: 0 0 1px #488aec;
    animation-name: glowD;
    animation-duration: 1s; }

.c-icon {
  position: absolute;
  right: 0;
  margin-right: 42px;
  top: 0;
  margin-top: 11px;
  cursor: pointer;
  color: transparent;
  transition: 0.3s all linear; }
  .c-icon:hover {
    color: #7a1818; }
  .c-icon.active {
    color: #a02323; }

.i-icon {
  position: absolute;
  display: block;
  right: 0;
  margin-right: 10px;
  top: 0;
  margin-top: 7px;
  padding-top: 5px;
  background: transparent;
  color: #777;
  transition: 0.3s all linear;
  cursor: pointer; }
  .i-icon.i-left {
    right: auto;
    margin-right: auto;
    left: 0;
    margin-left: 10px; }

input.il {
  text-indent: 13px; }

.br-sm {
  padding: 2px 0;
  display: block;
  width: 100%; }

.has-more {
  text-align: distribute; }
  .has-more p {
    white-space: pre-line; }
    .has-more p img {
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.45) !important;
      float: left;
      display: block;
      margin: 0 10px 10px 0; }
      .has-more p img.img-right {
        float: right;
        margin-right: 0;
        margin: 0 0 10px 10px; }
  .has-more .less-bt {
    display: none; }
  .has-more .a-more {
    margin: 0;
    padding: 0;
    display: none; }
  .has-more.expanded .more-bt {
    display: none; }
  .has-more.expanded .less-bt {
    display: block; }
  .has-more.expanded .a-more {
    display: block; }

.badge {
  -webkit-border-radius: 1px !important;
  -moz-border-radius: 1px !important;
  border-radius: 1px !important; }

.badge:hover {
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important; }

.badge-error {
  background-color: #a02323 !important; }

.badge-error:hover {
  background-color: #7a1818 !important; }

.badge-warning {
  background-color: #1b98e0 !important; }

.badge-warning:hover {
  background-color: #2377a7 !important; }

.badge-success {
  background-color: #20ab00 !important; }

.badge-success:hover {
  background-color: #125f00 !important; }

.badge-info {
  background-color: #019ECB !important; }

.badge-info:hover {
  background-color: #0167A9 !important; }

.badge-inverse {
  background-color: #333333 !important; }

.badge-inverse:hover {
  background-color: #1a1a1a !important; }

.nml {
  margin-left: 0px !important; }

.nmr {
  margin-right: 0px !important; }

.caps {
  text-transform: capitalize; }

@media screen and (max-width: 1366px) {
  li:nth-last-child(1).icon-only > ul {
    margin-left: 0;
    right: 0; }
  li:nth-last-child(2).icon-only > ul {
    margin-left: 0;
    right: 0; }
  nav > ul {
    padding-right: 2px !important; }
    nav > ul > li {
      padding: 15px 10px !important; }
      nav > ul > li.icon-only {
        padding: 13px 0px !important; }
      nav > ul > li > a {
        font-size: 110% !important; }
      nav > ul > li > ul {
        margin-left: -75px !important; } }

hr {
  display: block;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border: none;
  border-bottom: 1px #dbdbdb solid; }

.panel-header h3 {
  padding: 0px 10px; }

.cover-img {
  padding: 0px !important;
  padding-top: 100px !important;
  padding-bottom: 10px !important;
  max-width: 100vw !important;
  overflow: hidden !important;
  margin: 0px !important; }
  .cover-img .tour-cover {
    padding: 0px !important; }

.cover-img + .content-placeholder {
  padding-top: 0px !important; }

.breadcrumb {
  padding: 0px;
  padding-top: 115px;
  padding-bottom: 5px;
  padding-left: 10px;
  max-width: 100vw;
  overflow: hidden;
  margin: 0px;
  background-color: #f1f1f1;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12) inset;
  text-align: left; }
  .breadcrumb span {
    color: #777;
    font-size: 13px;
    cursor: pointer; }
    .breadcrumb span a {
      color: #666;
      transition: color 0.2s; }
      .breadcrumb span a.active span {
        color: #a02323; }
        .breadcrumb span a.active span i {
          color: #a02323; }
      .breadcrumb span a:hover {
        color: #a02323; }
        .breadcrumb span a:hover span {
          color: #a02323; }
          .breadcrumb span a:hover span i {
            color: #a02323; }
    .breadcrumb span:nth-child(1) {
      padding-left: 5px; }
    .breadcrumb span i {
      font-size: 18px;
      cursor: default;
      color: #999;
      line-height: 21px;
      vertical-align: middle; }
    .breadcrumb span:hover {
      color: #a02323; }

.breadcrumb + .content-placeholder {
  padding-top: 0px !important; }

@media screen and (max-width: 991px) {
  .social {
    text-align: center !important; }
    .social .fsocial {
      display: inline-block; }
  .nml {
    margin-left: -15px !important; }
  .nmr {
    margin-right: -15px !important;
    margin-bottom: 6px; }
  .s-icon {
    margin-right: 22px; }
  .pt {
    padding: 8px 0px !important; } }

@media screen and (max-width: 839px) {
  .cover-img {
    padding-top: 80px !important; }
  .breadcrumb {
    padding-top: 85px; }
  .header {
    position: fixed;
    width: 100%;
    max-width: 100vw;
    height: 80px;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.123);
    z-index: 100; }
    .header.admin nav {
      margin-top: 61px !important; }
    .header.admin.mini {
      height: 55px !important; }
      .header.admin.mini .menu {
        height: 60px !important; }
        .header.admin.mini .menu > nav {
          margin-top: 61px !important;
          max-height: calc(100vh - 61px); }
      .header.admin.mini .logo img {
        margin-top: 5px !important;
        max-width: auto !important; }
    .header.mini {
      height: 60px; }
      .header.mini .h-wrapper > .settings {
        display: none; }
      .header.mini .h-wrapper > .menu {
        height: 60px; }
        .header.mini .h-wrapper > .menu > nav {
          margin-top: 60px;
          max-height: calc(100vh - 60px); }
      .header.mini .h-wrapper > .logo img {
        padding: 0;
        margin-top: 5px;
        margin-left: 5px; }
    .header .h-wrapper .logo {
      max-width: 300px; }
      .header .h-wrapper .logo a img {
        padding-top: 0px !important; }
    .header .h-wrapper .menu > .toggle {
      display: block; }
    .header .h-wrapper .menu > nav {
      position: absolute;
      height: auto;
      top: 0;
      left: 0;
      margin-top: 80px;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.123);
      vertical-align: middle;
      display: none;
      z-index: 100;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
      overflow-x: hidden; }
      .header .h-wrapper .menu > nav > ul {
        display: block;
        width: 100%;
        height: auto;
        z-index: 100; }
        .header .h-wrapper .menu > nav > ul > li {
          float: none;
          padding: 10px 0 !important;
          min-height: 30px;
          height: auto;
          border-bottom: 1px #fafafa solid;
          display: block;
          transition: none !important;
          z-index: 100; }
          .header .h-wrapper .menu > nav > ul > li.icon-only {
            padding: 0; }
            .header .h-wrapper .menu > nav > ul > li.icon-only > a > i {
              display: none; }
            .header .h-wrapper .menu > nav > ul > li.icon-only > a span {
              display: block; }
            .header .h-wrapper .menu > nav > ul > li.icon-only > a label {
              left: 0px;
              margin-top: 0px;
              padding: 3px 7px;
              font-size: 13px;
              margin-left: 10px; }
            .header .h-wrapper .menu > nav > ul > li.icon-only > i {
              position: absolute;
              right: 0;
              margin-right: 10px;
              margin-top: -22px; }
          .header .h-wrapper .menu > nav > ul > li > a > i {
            border: none;
            padding: 0;
            display: none; }
          .header .h-wrapper .menu > nav > ul > li > a > span {
            display: block; }
          .header .h-wrapper .menu > nav > ul > li > i {
            position: absolute;
            right: 0;
            margin-right: 10px;
            margin-top: -22px;
            color: #999; }
          .header .h-wrapper .menu > nav > ul > li:hover {
            height: auto;
            background-color: rgba(0, 0, 0, 0.03); }
            .header .h-wrapper .menu > nav > ul > li:hover > a {
              color: #7a1818; }
            .header .h-wrapper .menu > nav > ul > li:hover > i {
              color: #666; }
          .header .h-wrapper .menu > nav > ul > li > ul {
            position: static;
            padding: 0 !important;
            box-shadow: -1px -2px 3px 1px transparent;
            width: 100%;
            margin: 0 !important;
            margin-top: 10px !important;
            overflow: hidden;
            transition: none !important;
            display: none;
            visibility: visible !important;
            opacity: 1 !important;
            z-index: 100; }
            .header .h-wrapper .menu > nav > ul > li > ul.lg {
              margin-left: 0; }
            .header .h-wrapper .menu > nav > ul > li > ul > li {
              z-index: 100; }
              .header .h-wrapper .menu > nav > ul > li > ul > li > a {
                font-size: 95%;
                height: 20px; }
              .header .h-wrapper .menu > nav > ul > li > ul > li.title {
                min-height: 38px; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.title > .settings {
                  margin-right: 5px; }
                  .header .h-wrapper .menu > nav > ul > li > ul > li.title > .settings:before {
                    content: "Manage ";
                    font-family: "Raleway", sans-serif;
                    font-size: 12px;
                    height: 12px;
                    line-height: 17px;
                    vertical-align: text-top; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.title > img {
                  max-width: 35px;
                  float: left;
                  display: block;
                  margin: 0; }
                .header .h-wrapper .menu > nav > ul > li > ul > li.title > span {
                  margin-top: 10px; }
              .header .h-wrapper .menu > nav > ul > li > ul > li:hover {
                background-color: rgba(0, 0, 0, 0.05); }
                .header .h-wrapper .menu > nav > ul > li > ul > li:hover > a {
                  cursor: default;
                  color: #7a1818; }
  .content-placeholder {
    padding: 90px 0 10px 0; }
  #tc-ul {
    width: 100%;
    margin-left: 0px; } }

.alert {
  display: block;
  font-size: 14px;
  display: none;
  overflow: hidden;
  line-height: 22px; }
  .alert.active {
    padding: 8px 6px;
    margin-bottom: 5px;
    display: block; }
  .alert i {
    line-height: 20px;
    vertical-align: middle;
    display: none; }
  .alert ul {
    padding: 2% 8%;
    margin: 0;
    list-style: square; }
  .alert.danger {
    color: #a02323;
    background: rgba(160, 35, 35, 0.15); }
    .alert.danger .danger {
      display: inline-block; }
  .alert.success {
    color: #20ab00;
    background: rgba(32, 171, 0, 0.15); }
    .alert.success .success {
      display: inline-block; }
  .alert.warning {
    color: #2377a7;
    background: rgba(27, 152, 224, 0.15); }
    .alert.warning .warning {
      display: inline-block; }
  .alert.info {
    color: #0167A9;
    background: rgba(1, 158, 203, 0.15); }
    .alert.info .info {
      display: inline-block; }

@media screen and (max-width: 600px) {
  .has-more p img {
    min-width: 100%;
    height: auto;
    margin: 0 auto;
    float: none;
    max-width: 100%; }
    .has-more p img.img-right {
      margin: 0 auto;
      margin-bottom: 5px;
      float: none; }
  .panel-body {
    padding: 3px !important; }
  .nprl-xs {
    padding-right: 0px !important;
    padding-left: 0px !important; } }

@media screen and (max-width: 360px) {
  .logo {
    margin-top: 13px; }
    .logo a img {
      padding: 5px !important;
      max-width: 230px !important; } }

.browse {
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 18px;
  color: #cc2626;
  cursor: pointer;
  right: 0;
  z-index: 100;
  transition: color 0.2s; }
  .browse:hover {
    color: #7a1818; }

.fc-elastic {
  height: auto !important;
  max-height: 100% !important; }

.b-icon {
  display: block;
  margin: 0;
  margin-top: -2px !important;
  padding: 0 !important;
  float: left;
  margin-right: 5px; }

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.allsp > div {
  padding: 2px !important; }
  .allsp > div > div {
    margin-bottom: 0px !important; }

.simple-ul {
  list-style: none;
  color: #666;
  padding: 0;
  margin: 0;
  text-align: left; }
  .simple-ul > li {
    font-weight: 600;
    padding: 8px 15px; }
    .simple-ul > li span {
      line-height: 16px;
      vertical-align: middle;
      font-size: 24px;
      margin-right: 5px;
      color: #1b98e0; }

.atour {
  padding: 0px !important;
  opacity: 1.0;
  visibility: visible;
  transition: 0.3s all;
  position: relative;
  top: inherit; }
  .atour.ahide {
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden; }

.hide-in {
  display: none; }
