.carousel img{
	min-width: 100%;
}
.sr-only{
	position: absolute;
	left: -9999999px;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
//carousel start
.carousel {
  position: relative;
  z-index: 0;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
       -o-transition: .6s ease-in-out left;
          transition: .6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
         -o-transition:      -o-transform .6s ease-in-out;
            transition:         transform .6s ease-in-out;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7%;
  min-width: 50px;
  font-size: 20px;
  color: $lightorange;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  filter: alpha(opacity=50);
  opacity: .5;
}
.carousel-control.right {
  right: 0;
  left: auto;
}
.carousel-control:hover,
.carousel-control:focus {
  color: $darkorange;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9;
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  font-size: 30px;
  margin-top: -20px;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 0;
  margin-left: 0px;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 0;
  margin-right: 0px;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-weight: 700;
  line-height: 1;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}
.carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
  margin-right: 10px;
  z-index: 15;
  width: 60%;
  text-align: right;
  list-style: none;

}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: $orange;
  border-radius: 0;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: $red;
}
.carousel-indicators{
  li{
    &:hover {
      width: 12px;
      height: 12px;
      background-color: $red;
    }
  }
}
.carousel-price{
  display: none;
  text-align: center;
  position: absolute;
  top:0;
  margin-top: 20px;
  left:0;
  margin-left: 20px;
  font-style: italic;
  background-color: $darkgreen;
  padding: 10px;
  font-size: 100%;
  line-height: 20px;
  box-shadow: 0 0 0 4px $yellow,4px 6px 3px 0 rgba(0,0,0,0.3);
  z-index: 1000;
  span{
    color:#fff;
  }
  h4{
    font-weight: bold;
    font-size: 30px;
    padding: 0;
    margin: 0;
    color:gold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    small{
      color:#fff;
      font-size: 14px;
    }
  }
}
.carousel-hot{
  text-align: center;
  position: absolute;
  bottom:0;
  right:0;
  font-style: italic;
  background-color: $darkred;
  margin-bottom: 18%;
  margin-right: 14%;
  padding: 4px;
  font-size: 70%;
  line-height: 15px;
  box-shadow: 0 0 0 2px $orange,-4px 6px 3px 0 rgba(0,0,0,0.3);
  border-radius: 50%;
  z-index: 1000;
  span{
    color:#fff;
  }
  h4{
    font-weight: bold;
    font-size: 22px;
    padding: 0;
    margin: 0;
    color:#fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    small{
      color:#fff;
      font-size: 10px;
    }
  }
}
.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  padding: 5px;
  z-index: 140;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  text-align: left;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  h4{
    margin: 0;
    padding: 0;  
    font-size: 18px;
    font-weight: 700;
    color:#fff;
    small{
      color:gold;
      font-size: 14px;
      .bt{
        display: inline-block;
      }
    }
  }
  p{
    display: none;
    font-size: 20px;
    font-weight: 400;
    padding: 5px 15px;
    padding-top: 10px;
    margin-top: 0;
    font-style: italic;
    color:#cfcfcf;
    &:before{
      content:'"';
    }
    &:after{
      content:'"';
    }
    a{
      color:gold;
      &:hover{
        color:$lightred;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 40px;
    height: 70px;
    margin-top: -35px;
    font-size: 70px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    left: 0;
    margin-left: 10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: 30px;
    right: 0;
  }
  .carousel-caption {
    left: 0;
    padding-bottom: 10px;
    h4{
      padding: 10px;
      font-size: 28px;
      small{
        font-size: 18px;
        .bt{
          display: none;
        }
      }
    }
    p{
      display: block;
      padding: 0 15px;
    }
  }
  .carousel-price{
    display: block;
  }
  .carousel-hot{
    margin-bottom: 20%;
    margin-right: 10%;
    padding: 10px;
    font-size: 100%;
    line-height: 20px;
    box-shadow: 0 0 0 4px $orange,-4px 6px 3px 0 rgba(0,0,0,0.3);
    span{
      color:#fff;
    }
    h4{
      font-weight: bold;
      font-size: 30px;
      padding: 0;
      margin: 0;
      color:#fff;
      text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
      small{
        color:#fff;
        font-size: 14px;
      }
    }
  }
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-indicators li {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $orange;
    border-radius: 0;
  }
  .carousel-indicators .active {
    width: 20px;
    height: 20px;
    margin: 0;
    background-color: $red;
  }
  .carousel-indicators{
    li{
      &:hover {
        width: 20px;
        height: 20px;
        background-color: $red;
      }
    }
  }
}
//carousel-end