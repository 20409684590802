.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: "Verdana", sans-serif;
    font-weight: 300;
    font-size: 100%;
    &:hover{
    	color:$darkgreen;
    }
    i{    
      line-height: 30px;
      vertical-align: middle;
    }
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.22);
    transition: 0.2s all;
  }

  // Box hover
  &:hover + label:before {
    background: $lightgreen;
    box-shadow: 0 0 0 1px $darkgreen;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.32);
    transition: 0.2s all;
  }

  // Box checked
  &:checked + label:before {
    background: #fff;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: $darkgreen;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 $darkgreen,
      4px 0 0 $darkgreen,
      4px -2px 0 $darkgreen,
      4px -4px 0 $darkgreen,
      4px -6px 0 $darkgreen,
      4px -8px 0 $darkgreen;
    transform: rotate(45deg);
  }
}