@import 'variables';
@import 'carousel';
@import 'table';
@import 'grid';
@import 'forms';
@import 'panel';
@import 'checkbox';
// @import 'bootstrap';
html{
	padding: 0;
	margin: 0;
	height: auto;
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
	font-family: $default-font;
	body{
		background-color: #f7f7f7;
		font-family: $default-font;
		margin: 0;
		padding: 0;
		height: auto;
		width: 100%;
		max-width: 100vw;
		display: block;
		text-align: center;
		&.admin{
			display: table!important;
			padding-top: 80px!important;
			text-align: left;
			min-height: 100vh!important;
		}
	}
}
//
.header{
	transition: height 0.2s ease;
	position: fixed;
	width: 100%;
	max-width: 100vw;
	height: 110px;
	top:0;
	left: 0;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.203);
	z-index: 10000;
	&.admin{
		height: 60px;
		.logo{
			img{
				padding: 0px!important;
				max-height: 50px;
				margin-top: 7px;
				width: auto!important;
			}
		}
		nav{
			margin-top: 10px!important;
			ul{
				li{
					&:hover{
						>ul{
							visibility: visible;
							opacity: 1.0;
							margin-top: -7px!important;
						}
					}
				}
			}
		}
	}
	.h-wrapper{
		height: 100%;
		width: 1200px;
		max-width: 100%;
		margin: 0 auto;
		>.settings{
			height: 20px;
			width: 100%;
			>ul{
				padding: 0;
				margin: 0;
				list-style: none;
				display: block;
				float: right;
				z-index: 10;
				>li{
					float: right;
					padding: 0 5px 0 0;
					transition: all 0.3s ease;
					>a{
						font-size: 70%;
						>i{
							font-size: 15px;
							position: absolute;
							&:nth-child(1){
								margin-left: -20px;
							}
							&:nth-child(2){
								margin-left: 11px;
								margin-top: -13px;
							}
						}
						padding: 5px 10px 5px 20px;
						display: inline-block;
						color: #777;
						text-decoration: none;
						transition: $color liner;
						&:hover{
							color: $lightred;
						}
						&:active{
							color: $red;
						}
					}
					>ul{
						list-style: none;
						margin: 0;
						padding: 3px;
						background-color: #fafafa;
						box-shadow: 1px 2px 3px 1px rgba(0,0,0,0.123);
						position: absolute;
						width: 100px;
						top:0;
						margin-top: 20px;
						transition: all 0.3s ease-in-out;
						visibility: hidden;
						opacity: 0.0;
						&.lg{
							margin-left: -60px;	
						}

						>li{
							padding: 0 5px;
							text-align: center;
							vertical-align: middle;
							min-height: 20px;
							line-height: 20px;
							>a{
								display: block;
								text-decoration: none;
								font-size: 80%;
								min-height: 20px;
								color:#666;
								font-weight: normal;
								transition:$color linear;
							}
							> i{
								display: block;
								cursor: default;
								color:#999;
								transition:$color linear;
							}
							&.active{
								>a{
									cursor: default;
									color:#999;
									text-decoration: underline;
								}
								background-color: rgba(0,0,0,0.03);
								&:hover{
									a{
										color:#999;
									}
								}
							}
							&:hover{
								>a{
									color:$darkred;
									text-decoration: underline;
								}
								>i{
									color:#666;
								}
							}
						}
					}
					&:hover{
						>a{
							color:$darkred;
						}
						>i{
							color:#999;
						}
						>ul{
							visibility: visible;
							opacity: 1.0;
							margin-top: 20px;
						}
					}
				}
			}
		}
		.logo{
			height: auto;
			display: block;
			padding: 0;
			float: left;
			min-width: 40%;
			max-width: 250px;
			transition: 0.5s all;
			a{
				display: block;
				padding: 0;
				margin: 0;
				img{
					transition: 0.5s all;
					padding: 10px;
					max-width: calc(100% - 20px);
					width: 300px;
					float: left;
					min-width: 150px;
				}
			}
		}
		.menu{
			height: 90px;
			max-width: 58%;
			float: right;
			>.toggle{
				display: none;
				position: absolute;
				right: 0;
				margin-right: 5px;
				bottom: 0;
				margin-bottom: 0;
				padding: 0;
				cursor: pointer;
				width: 77px;
				line-height: 30px;
				vertical-align: middle;
				height: 30px;
				color:#666;
				>span{
					z-index: -1;
					cursor: pointer;
					position: absolute;
					left: 0;
					margin-top: 0px;
				}
				>i{
					z-index: -1;
					font-size: 170%;
					cursor: pointer;
					position: absolute;
					right: 0;
					&.close{
						display: none;
						color:$darkred;
					}
				}
			}
			>nav{			
				height: 60px;	
				margin-top: 30px;
				vertical-align: text-bottom;
				transition: margin 0.2s ease;
				>ul{
					list-style: none;
					padding: 0;
					margin: 0;
					width: 100%;
					height: 100%;
					>li{
						float: left;
						padding: 10px 15px;
						text-align: center;
						&.icon-only{
							padding: 10px 5px;
							>a{
								>i{
									cursor: pointer;
									margin-top: -13px;
								}
								span{
									display: none;
								}
								label{
									position: absolute;
									margin-top: -12px;
									margin-left: 29px;
									font-size: 12px;
									font-family: "Arial", sans-serif;
									font-weight: 600;
									color:#fff;
									padding: 1px 4.5px;
									z-index: -1;
									&.green{
										background-color: $green;
									}
									&.orange{
										background-color: $orange;
									}
									&.red{
										background-color: $red;
									}
									&.blue{
										background-color: $blue;
									}
								}
							}
							>i{
								margin-top: -7px;
							}
							>ul{
								>li{
									text-align: left;
									cursor: pointer;
									>a{
										display: inline-block;
									}
									>i{
										&.addon{
											position: absolute;
											display: inline-block;
											right: 0;
											width: 20px;
											margin-top: 0px;
											margin-right: 3px;
											font-size: 110%;
											&.count{
												width: 25px;
												padding: 2px 0;
												background-color: #bababa;
												color: #fff;
												text-align: center;
												font-family: "Arial", sans-serif;
												font-style: normal;
												font-size: 75%;
												margin-top: 0px;
											}
										}
									}
								}
							}
						}
						&.active{
							>a{
								color: $darkred;
							}
						}
						>a{
							display: block;
							text-decoration: none;
							font-size: 120%;
							color:#666;
							font-weight: normal;
							transition:$color linear;
							>i{
								border: 1px transparent solid;
								padding: 10px;
							}
						}
						> i{
							display: block;
							cursor: default;
							color:transparent;
							transition:color 0.5s linear;
						}
						&:hover{
							>a{
								color:$darkred;
								>i{
									border: 1px #dbdbdb solid;
								}
								&:hover{
									label{
										z-index: 100;
									}
								}
							}
							>i{
								color:#999;
							}
							>ul{
								visibility: visible;
								opacity: 1.0;
								margin-top: 0px;
							}
						}
						>ul{
							list-style: none;
							margin: 0;
							padding: 5px;
							background-color: #fafafa;
							box-shadow: 1px 2px 3px 1px rgba(0,0,0,0.123);
							position: absolute;
							width: 175px;
							margin-left: -60px;
							margin-top: 20px;
							transition: all 0.3s ease-in-out;
							visibility: hidden;
							opacity: 0.0;
							&.lg{
								margin-left: -30px;
							}
							&.sg{
								margin-left: -45px;
							}
							>li{
								padding: 5px 5px;
								text-align: center;
								cursor: pointer;
								>a{
									display: block;
									text-decoration: none;
									font-size: 95%;
									min-height: 20px;
									color:#666;
									font-weight: normal;
									transition:$color linear;
								}
								> i{
									display: block;
									cursor: pointer;
									color:#999;
									transition:$color linear;
								}
								>ul{
									list-style: none;
									margin: 0;
									padding: 5px;
									background-color: #fafafa;
									box-shadow: 1px 2px 3px 1px rgba(0,0,0,0.123);
									width: auto;
									min-width:140px;
									margin-left: -150px;
									position: absolute;
									top:0;
									margin-top:0px;
									transition: all 0.3s ease-in-out;
									visibility: hidden;
									opacity: 0.0;
									&.lg{
										margin-left: -60px;	
									}
									>li{
										padding: 5px 5px;
										text-align: center;
										>a{
											display: block;
											text-decoration: none;
											font-size: 95%;
											height: 20px;
											color:#666;
											font-weight: normal;
											transition:$color linear;
										}
										> i{
											display: block;
											cursor: default;
											color:#999;
											transition:$color linear;
										}
										&:hover{
											>a{
												color:$darkred;
												text-decoration: underline;
											}
											>i{
												&.addon{
													color:$red;
													&.count{
														background-color:$red;
													}
												}
											}
											>i{
												color:#666;
											}
										}
									}
								}
								&:hover{
									>a{
										color:$darkred;
										text-decoration: underline;
									}
									>i{
										&.addon{
											color:$red;
											&.count{
												background-color:$red;
											}
										}
									}
									>i{
										color:#666;
									}
									>ul{
										visibility: visible;
										opacity: 1.0;
									}
									background-color:rgba(0,0,0,0.03);
								}
								&.title{
									background-color: rgba(255,255,255, 0.7);
									text-align: center;
									&.has-img{
										min-height: 43px!important;
									}
									>.settings{
										position: absolute;
										right: 0;
										font-size: 100%;
										margin-right: 55px;
										margin-top:	10px;
										color:#999;
										cursor: pointer;
										&:hover{
											color: $red;
										} 
										&:active{
											color: $darkred;
										} 
									}
									>img{
										height: 35px;
										width: 35px;
										border-radius: 50%;
										display: block;
										margin: 0 auto;
										margin-bottom: 5px;
									}
									>span{
										color: #888;
										cursor: default;
										font-size: 90%;
										display: block;
									}
								}
								&.checkout{
									>i{
										color: $green;
										&.addon{
											font-size: 24px;
											width: 30px;
											margin-top: -2px;
											margin-right: 4px;
										}
									}
									&:hover{
										>a{
											color:$darkgreen;
										}
										>i{
											&.addon{
												color:$darkgreen;
											}
										}
									}
								}
								&.active{
									>a{
										cursor: default;
										color:$darkred;
									}
									background-color: rgba(0,0,0,0.05);
									&:hover{
										a{
											text-decoration: none;
										}
									}
								}
								&.separator{
									height: 0px;
									max-height: 0px;
									padding: 0;
									margin: 0 2px;
									border-bottom: 1px solid #efefef;
								}
							}
						}
					}
				}
			}
		}
	}
	&.mini{
		height: 60px;
		.h-wrapper{
			>.settings{
				display: none;
			}
			>.menu{
				height: 60px;
				>nav{
					margin-top: 0;
					>ul{
						height: 100%;
						>li{
							padding-bottom: 0px!important;
							height: calc(100% );
							>a{
								margin-top: 5px;
							}
							>ul{
								margin-top: -7px;
							}
						}
					}
				}
			}
			>.logo{
				img{
					padding: 5px;
				}
			}
		}
	}
}
#tc-ul{
	width: 290px;
	margin-left: -125px;
}
.mr{
	margin-right: 5px!important;
}
//paggination
.paggination{
	margin-top: 5px;
	padding: 0px;
	text-align: center;
	height: 20px;
	vertical-align: middle;
	line-height: 20px;
	color:#777;
	a{
		color:$blue;
		i{
			height: 20px;
			vertical-align: middle;
			line-height: 18px;
		}
	}
}
// Search
#search{
	.empty-message {
	  padding: 5px 10px;
	  text-align: center;
	}
}

// blockers
@keyframes pulse {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
.blocker {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(#fff,0.9);
	z-index: -10;
	visibility: hidden;
	opacity: 0.0;
	transition: 0.3s opacity ease;
	&.active{
		z-index: 9999!important;
		visibility: visible;
		opacity: 1.0;
	}
	.ldr {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		margin: auto;
		width: 2.5em;
		height: 2.5em;
		span{
			position: absolute;
			margin-top: 2.75em;
			font-weight: 300;
			font-size: 14px;
			color:#666;
		}
		.ldr-blk {
			height: 35%;
			width: 35%;
			animation: pulse 0.75s ease-in infinite alternate;
			background-color: darken($darkorange, 0%);
			&.an_delay {
			animation-delay: 0.75s;
			}
		}
	}
}


.twitter-typeahead{
	display: block!important;
	.tt-menu{
		box-shadow: 1px 1px 14px 0px rgba(0,0,0,0.11);
		width: 100%;
		background-color: #fff;
		.tt-suggestion{
			text-align: left;
			padding: 10px 10px;
			&.tt-cursor{
				background-color: $orange;
				color:#fff;
			}
		}
	}
}

//modals
#modal-blocker{
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255, 0.756);
	top:0;
	left: 0;
	z-index: 150;
	visibility: visible;
	opacity: 1.0;
	transition: 0.2s opacity ease-in;
	z-index: 10001;
	&.closed{
		opacity: 0.0;
		visibility: hidden;
		transition: 0.3s opacity ease-out;
		z-index: 149;
		#modal{
			transition: 0.3s top ease-out;
			top:-20%;
		}
	}
}
#filter-blocker{
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 300px!important;
	background-color: rgba(255,255,255, 0.756);
	top:0;
	left: 0;
	z-index: 150;
	visibility: visible;
	opacity: 1.0;
	transition: 0.2s opacity ease-in;
	z-index: 10001;
	&.closed{
		opacity: 0.0;
		visibility: hidden;
		transition: 0.3s opacity ease-out;
		z-index: 149;
	}
}
#modal{
	position: absolute;
	height: auto;
	width: 500px;
	min-height: 150px;
	max-width: 100%;
	max-height: 100vh;
	top:10%;
	left: 50%;
	transform: translate(-50%,-10%);
	background-color: #fff;
	border-radius: 1px;
	border: 1px rgba(0,0,0,0.153) solid;
	box-shadow: 1px 2px 14px 0 rgba(0,0,0,0.223);
	transition: 0.5s top ease;
	#modal-content{
		width: 100%;
		height: 100%;
		overflow: hidden;
		margin: 0;
		padding: 0;
		opacity: 0.0;
		transition: 0.5s opacity ease-in;
		&.active{
			opacity: 1.0;
			transition: 0.3s opacity ease-out;
		}
	}
	&.modal-md{
		width: 800px;
	}
	.modal-header{
		padding: 10px;
		border-bottom: 1px solid #dbdbdb;
		height: 68px;
		.modal-title{
			display: inline-block;
			h4{
				font-size: 24px;
				color:$darkorange;
				margin: 0;
				padding: 15px 0 5px 0;
				small{
					color:#ababab;
					font-size: 70%;
					font-weight: 400;
				}
			}
		}
		.modal-close{
			display: block;
			float: right;
			color:#666;
			cursor: pointer;
			font-size: 80%;
			height: 20px;
			line-height: 10px;
			text-align: text-top;
			span{
				position: absolute;
				margin-right: 5px;
				right: 0;
				cursor: pointer;
				z-index: 10000!important;
			}
			&:hover{
				color:$red;
			}
		}
	}
	.modal-body{
		height: auto;
		padding: 10px;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: calc(100vh - 140px);
	}
	.modal-footer{
		padding: 10px;
		display: block;
		height: auto;
		min-height: 55px;
		.modal-yes{
			float: right;
			display: block;
			margin-left: 5px;
		}
		.modal-no{
			float: right;
		}
	}
}
.content-placeholder{
	transition: padding 0.2s ease;
	height: 100%;
	margin: 0 auto;
	padding: 120px 0 20px ;
	width:1080px;
	min-height: calc(100vh - 139px);
	max-width: 100%;
	display: inline-block;
	&.mini{
		padding-top: 60px;
	}
	&.admin{
		display: table-row!important;
		height: 100%!important;
	}
}
.footer{
	&.admin{
		display: table-row!important;
		text-align: center;
	}
	width: 100%;
	max-width: 100vw;
	height: auto;
	background-color: #e7e7e7;
	margin: 0;
	display: block;
	.container-fluid{
		padding-top:15px;
		padding-bottom: 15px;
		.logo{
			img{
				filter: grayscale(100%) brightness(105%) contrast(90%);
				transition:0.2s;
				max-height: 100px;
				min-height: 100px;
			}
			&:hover{
				img{
					filter: grayscale(0%) brightness(100%) contrast(100%);	
				}
			}
		}
		.social{
			text-align: right;
			display: block;
		}
		.fsocial{
			display: inline-block;
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    margin: 0;
		}
		.fsocial>li{
		    float: left;
		    width: auto;
		    padding:3px;
		    border-radius: 50%;
		    text-shadow: 1px 1px rgba(0,0,0,0.3);
			
		}
		.fsocial>li>a>img{
		    color:#fff;
		    text-decoration: none;
		    cursor: pointer;
		    font-size: 110%;
			filter: grayscale(100%) brightness(105%) contrast(90%);
			transition:0.2s;
			&:hover{
				filter: grayscale(0%) brightness(100%) contrast(100%);
			}
		}
		.quick-links{
			list-style: none;
			text-align: center;
			margin: 10px 0;
			padding: 0;
			display: block;
			li{
				text-align: center;
				margin: 5px 0;
				a{
					color:#666;
					padding: 10px 5px;
					margin: 5px;
					font-size: 14px;
					transition:$color;
					img{
						filter: grayscale(100%) brightness(105%) contrast(90%);
						transition:0.2s;
						max-height: 30px;
						margin-top: 3px;
					}
					&:hover{
						color:$red;
						img{
							filter: grayscale(0%) brightness(100%) contrast(100%);
						}
					}
				}
			}
		}
	}
}

//
.dd-icon{
	font-size: 16px!important;
	display: inline-block;
	line-height: 21px;
	vertical-align: middle;
}
.s-icon{
	position: absolute;
	display: block;
	right: 0;
	margin-right: 10px;
	top:0;
	margin-top: 7px;
	padding-top: 5px;
	background: transparent;
	border: none;
	color:#777;
	transition: 0.3s all linear;
	outline: none;
	cursor: pointer;
	border-radius: 50%;
	i{
		cursor: pointer;
	}
	&:disabled{
		color:#e1e1e1;
		cursor: default;
		&:hover, &:focus{
			color:#e1e1e1;
			text-shadow: none;
			animation-name: none;
		}
		i{
			cursor: default;
		}
	}
	&:hover, &:focus{
		color:$blue;
		text-shadow: 0 0 1px $lightblue;
		animation-name: glowD;
		animation-duration: 1s;
	}
}
.c-icon{
	position: absolute;
	right: 0;
	margin-right: 42px;
	top:0;
	margin-top: 11px;
	cursor: pointer;
	color:transparent;
	transition: 0.3s all linear;
	&:hover{
		color:$darkred;
	}
	&.active
	{
		color:$red;
	}
}
.i-icon{
	position: absolute;
	display: block;
	right: 0;
	margin-right: 10px;
	top:0;
	margin-top: 7px;
	padding-top: 5px;
	background: transparent;
	color:#777;
	transition: 0.3s all linear;
	cursor: pointer;
	&.i-left{
		right: auto;
		margin-right: auto;
		left: 0;
		margin-left: 10px;
	}
}
input.il{
	text-indent: 13px;
}
.br-sm{
	padding: 2px 0;
	display: block;
	width: 100%;
}
.has-more{
	text-align: distribute;
	p{
		white-space: pre-line;
		img{
			box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.45)!important;
			float: left;
			display: block;
			margin: 0 10px 10px 0;
			&.img-right{
				float: right;
				margin-right: 0;
				margin: 0 0 10px 10px;
			}
		}
	}
	.less-bt{
		display: none;
	}
	.a-more{
		margin: 0;
		padding: 0;
		display: none;
	}
	&.expanded{
		.more-bt{
			display: none;
		}
		.less-bt{
			display: block;
		}
		.a-more{
			display: block;
		}
	}
}
.badge {

  -webkit-border-radius: 1px!important;
  -moz-border-radius: 1px!important;
  border-radius: 1px!important;
}
.badge:hover {
  color: #ffffff!important;
  text-decoration: none!important;
  cursor: pointer!important;
}
.badge-error {
  background-color: $red!important;
}
.badge-error:hover {
  background-color: $darkred!important;
}
.badge-warning {
  background-color: $orange!important;
}
.badge-warning:hover {
  background-color: $darkorange!important;
}
.badge-success {
  background-color: $darkgreen!important;
}
.badge-success:hover {
  background-color: darken($darkgreen, 15%)!important;
}
.badge-info {
  background-color: $cyan!important;
}
.badge-info:hover {
  background-color: $darkcyan!important;
}
.badge-inverse {
  background-color: #333333!important;
}
.badge-inverse:hover {
  background-color: #1a1a1a!important;
}

.nml{
	margin-left: 0px !important;
}
.nmr{
	margin-right: 0px !important;
}
.caps{
	text-transform:capitalize;
}
@media screen and (max-width: 1366px){
	li{
		&:nth-last-child(1){
			&.icon-only{
				>ul{
					margin-left: 0;
					right: 0;
				}
			}
		}
		&:nth-last-child(2){
			&.icon-only{
				>ul{
					margin-left: 0;
					right: 0;
				}
			}
		}
	}
	nav{
		>ul{
			padding-right: 2px!important;
			>li{
				padding: 15px 10px!important;
				&.icon-only{
					padding: 13px 0px!important;
				}
				>a{
					font-size: 110%!important;
				}
				>ul{
					margin-left: -75px!important;
				}
			}
		}
	}
}

hr{
	display: block;
    -webkit-margin-before: 0.5em;
    -webkit-margin-after: 0.5em;
    -webkit-margin-start: auto;
    -webkit-margin-end: auto;
    border:none;
	border-bottom: 1px #dbdbdb solid;
}
.panel-header{
	h3{
		padding: 0px 10px;
	}
}
.cover-img{
	padding: 0px!important;
	padding-top: 100px!important;
	padding-bottom: 10px!important;
	max-width: 100vw!important;
	overflow: hidden!important;
	margin: 0px!important;
	.tour-cover{
		padding: 0px!important
	}
}
.cover-img+.content-placeholder{
	padding-top: 0px!important;
}
.breadcrumb{
	padding: 0px;
	padding-top: 115px;
	padding-bottom: 5px;
	padding-left: 10px;
	max-width: 100vw;
	overflow: hidden;
	margin: 0px;
	background-color: #f1f1f1;
	box-shadow: 0 0 3px 0 rgba(#000, 0.12)inset;
	text-align: left;
	span{
		color:#777;
		font-size: 13px;
		cursor: pointer;
		a{
			color: #666;
			transition:$color;
			&.active{
				span{
					color: $red;
					i{
						color:$red;
					}
				}
			}
			&:hover{
				color: $red;
				span{
					color: $red;
					i{
						color:$red;
					}
				}
			}
		}
		&:nth-child(1){
			padding-left: 5px;
		}
		i{
			font-size: 18px;
			cursor: default;
			color:#999;
			line-height: 21px;
			vertical-align: middle;
		}
		&:hover{
			color:$red;
		}
	}
}
.breadcrumb+.content-placeholder{
	padding-top: 0px!important;
}

@media screen and (max-width: 991px){
	.social{
		text-align: center!important;
		.fsocial{
			display: inline-block;
		}
	}
	.nml{
		margin-left: -15px !important;
	}
	.nmr{
		margin-right: -15px !important;
		margin-bottom: 6px;
	}
	.s-icon{
		margin-right: 22px;
	}
	.pt{
		padding: 8px 0px!important;
	}
}
@media screen and (max-width: 839px){
	.cover-img{
		padding-top: 80px!important;
	}
	.breadcrumb{
		padding-top: 85px;
	}
	.header{
		position: fixed;
		width: 100%;
		max-width: 100vw;
		height: 80px;
		top:0;
		left: 0;
		background-color: #fff;
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.123);
		z-index: 100;
		&.admin{
			nav{
				margin-top: 61px!important;
			}
			&.mini{
				height: 55px!important;
				.menu{
					height: 60px!important;
					>nav{
						margin-top: 61px!important;
						max-height: calc(100vh - 61px);
					}
				}
				.logo{
					img{
						margin-top: 5px!important;
						max-width: auto!important;
					}
				}
			}
		}
		&.mini{
			height: 60px;
			.h-wrapper{
				>.settings{
					display: none;
				}
				>.menu{
					height: 60px;
					>nav{
						margin-top: 60px;
						max-height: calc(100vh - 60px);
					}
				}
				>.logo{
					img{
						padding:0;
						margin-top: 5px;
						margin-left: 5px;
					}
				}
			}
		}
		.h-wrapper{
			.logo{
				max-width: 300px;
				a{
					img{
						padding-top: 0px!important;
				
					}
				}
			}
			.menu{
				>.toggle{
					display: block;
				}
				>nav{			
					position: absolute;
					height: auto;
					top:0;
					left: 0;	
					margin-top: 80px;
					width: 100%;
					background-color: #fff;
					box-shadow: 0 0 3px 1px rgba(0,0,0,0.123);
					vertical-align: middle;
					display: none;
					z-index: 100;
					max-height: calc(100vh - 80px);
					overflow-y: auto;
					overflow-x: hidden;
					>ul{
						display: block;
						width: 100%;
						height: auto;
						z-index: 100;
						>li{
							float: none;
							padding: 10px 0!important;
							min-height: 30px;
							height: auto;
							border-bottom: 1px #fafafa solid;
							display: block;
							transition:none!important;
							z-index: 100;
							&.icon-only{
								padding: 0;
								>a{
									>i{
										display: none;
									}
									span{
										display: block;
									}
									label{
										left: 0px;
										margin-top: 0px;
										padding: 3px 7px;
										font-size: 13px;
										margin-left: 10px;
									}
								}
								>i{
									position: absolute;
									right:0;
									margin-right: 10px;
									margin-top: -22px;
								}
							}
							>a{
								>i{
									border: none;
									padding: 0;
									display: none;
								}
								>span{
									display: block;
								}
							}
							>i{
								position: absolute;
								right:0;
								margin-right: 10px;
								margin-top: -22px;
								color: #999;
							}
							&:hover{
								>a{
									color:$darkred;
								}
								>i{
									color:#666;
								}
								height: auto;
								background-color: rgba(0,0,0,0.03);
							}
							>ul{
								position: static;
								padding: 0!important;
								box-shadow: -1px -2px 3px 1px rgba(0,0,0,0.0);
								width: 100%;
								margin: 0!important;
								margin-top: 10px!important;
								overflow: hidden;
								transition:none!important;
								display: none;
								visibility: visible!important;
								opacity: 1!important;
								z-index: 100;
								&.lg{
									margin-left: 0;	
								}
								>li{
									z-index: 100;
									>a{
										font-size: 95%;
										height: 20px;
									}
									&.title{
										>.settings{
											margin-right: 5px;
											&:before{
												content: "Manage ";
												font-family: $default-font;
												font-size: 12px;
												height: 12px;
												line-height: 17px;
												vertical-align: text-top;
											}
										}
										min-height: 38px;
										>img{
											max-width: 35px;
											float: left;
											display: block;
											margin: 0;
										}
										>span{
											margin-top: 10px;
										}
									}
									&:hover{
										>a{
											cursor: default;
											color:$darkred;
										}
										background-color: rgba(0,0,0,0.05);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.content-placeholder{
		padding: 90px 0 10px 0;
	}
	#tc-ul{
		width: 100%;
		margin-left: 0px;
	}
}
.alert{
	display: block;
	font-size: 14px;
	display: none;
	overflow: hidden;
	line-height: 22px;
	&.active{
		padding: 8px 6px;
		margin-bottom: 5px;
		display: block;
	}
	i{
		line-height: 20px;
		vertical-align: middle;
		display: none;
	}
	ul{
		padding: 2% 8%;
		margin: 0;
		list-style: square;
	}
	&.danger{	
		color:$red;
		background: rgba($red, 0.15);
		.danger{
			display: inline-block;
		}
	}
	&.success{	
		color:$darkgreen;
		background: rgba($darkgreen, 0.15);
		.success{
			display: inline-block;
		}
	}
	&.warning{	
		color:$darkorange;
		background: rgba($orange, 0.15);
		.warning{
			display: inline-block;
		}
	}
	&.info{	
		color:$darkcyan;
		background: rgba($cyan, 0.15);
		.info{
			display: inline-block;
		}
	}
}
@media screen and (max-width: 600px) {
	.has-more{
		p{
			img{
				min-width: 100%;
				height: auto;
				margin: 0 auto;
				float: none;
				max-width: 100%;
				&.img-right{
					margin: 0 auto;
					margin-bottom: 5px;
					float: none;
				}
			}
		}
	}
	.panel-body{
		padding: 3px!important;
	}
	.nprl-xs{
		padding-right: 0px!important;
		padding-left: 0px!important;
	}

}
@media screen and (max-width: 360px){
	.logo{
		margin-top: 13px;
		a{
			img{
				padding: 5px!important;
				max-width: 230px!important;
		
			}
		}
	}
}
.browse{
	position: absolute;
	top:0;
	margin-top: 10px;
	margin-right: 10px;
	font-size: 18px;
	color:$lightred;
	cursor: pointer;
	right: 0;
	z-index: 100;
	transition: $color;
	&:hover{
		color:$darkred;
	}
}
.fc-elastic{
	height:auto!important;
	max-height:100%!important;
}
.b-icon{
	display: block;
	margin: 0;
	margin-top: -2px!important;
	padding: 0!important;
	float: left;
	margin-right: 5px;
}
.unselectable{
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.allsp{
	>div{
		padding: 2px!important;
		>div{
			margin-bottom: 0px!important;
		}
	}
}
.simple-ul{
	list-style: none;
	color:#666;
	padding: 0;
	margin: 0;
	text-align: left;
	>li{
		font-weight: 600;
		padding: 8px 15px;
		span{
			line-height: 16px;
			vertical-align: middle;
			font-size: 24px;
			margin-right: 5px;
			color:$orange;
		}
	}
}
// .row{
// 	max-width: 100vw!important;
// 	margin: 0!important;
// }
.atour{
	padding: 0px!important;
	opacity: 1.0;
	visibility: visible;
	transition: 0.3s all;
	position: relative;
	top:inherit;
	&.ahide{
		position: absolute;
		top:0;
		opacity: 0;
		visibility: hidden;
	}
}
.hide-in{
	display: none;
}