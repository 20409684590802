@import 'variables';
.tour-nav{
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		background-color: #f7f7f7;
		box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.22);
		display: inline-block;
		width: 100%;
		li{
			float: left;
			height: 40px;
			line-height: 40px;
			vertical-align: middle;
			border-right:1px solid #dbdbdb;
			min-width: 16.66%;
			transition: 0.2s;
			cursor: pointer;
			&:nth-last-child(1){
				border-right:none;
				min-width: calc(16.66% + 0.2px);	
			}
			a{
				text-decoration: none;
				color:#666;
				i{
					display: block;
					float: left;
					margin-left: 10px;
					margin-top: 8px;
				}
			}
			&:hover{
				background-color: $darkorange;
				a{
					color:#fff;
				}
			}
			&.active{
				background-color: $darkorange;
				border-color: $darkorange;
				a{
					color:#fff;
				}
				&.book{
					background-color: $darkgreen;
				}
			}
			&.book{
				background-color: $lightgreen;
				a{
					color:#fff;
				}
				&:hover{
					background-color: $darkgreen;
				}
			}
		}
	}
	&.sticky{
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		margin-top: 60px;
		z-index: 99;
		border-top:1px #dbdbdb solid;
		padding: 0;
		ul{
			background-color: rgba(#fff, 0.8);
		}
	}
}
.text-left{
	text-align: left;
}
.tour-cover{
	&.account{
		h1{
			.img-change{
				margin: 0 auto;
				width: 110px;
				max-width: 12vw;
				margin-bottom: 5px;
				border-radius: 50%;
				box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.3);
				overflow: hidden;
				.o-layer{
					position: absolute;
					width: 100%;
					height: 100%;
					max-width: 12vw;
					text-transform: none;
					font-size: 70%;
					top:0;
					left: 0;
					right: 0;
					border-bottom: 0;
					margin: 0 auto;
					max-height: 110px;
					max-width: 110px;
					text-align: right;
					a{
						text-shadow:none;
					}
				}
				img{
					display: block;
					margin: 0 auto;
					border-radius: 50%;
					width: 110px;
					height: 110px;
					max-height: 12vw;
					max-width: 12vw;
					background-color: #fff;
				}
			}
			white-space: nowrap;
			margin-bottom: 1%;
			text-align: center;
			margin-left: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		.change-cover{
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			label{
				cursor: pointer;
				padding-right: 20px;
				i{
					cursor: pointer;
				}
			}
		}
	}
	h1{
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: 4%;
		margin-bottom: 2%;
		text-transform: uppercase;
		color:#fff;
		text-shadow:1px 1px 1px rgba(#000, 0.2);
		font-size: 1.8em;
		z-index: 2;
		small{
			text-transform: none;
			text-shadow:none;
			color:#e9e9e9;
		}
		.rating{
			width: 100%;
			display: block;
			text-align: left;
			padding: 3px 5px;
			font-size: 18px;
			color:#fff;
			.stars{
				float: right;
				display: inline-block;
				i{
					color:gold;
					font-size: 110%;
				}
			}
		}
	}
	a{
		position: absolute;
		bottom: 2%;
		margin-bottom: 0;
		right: 2.2%;
		margin-right: 0;
		z-index: 99;
	}
	.over-layer{
		position: absolute;
		top:0;
		bottom: 0;
		left: 0;
		right:0;
		margin-left: 15px;
		width: calc(100% - 30px);
		height: calc(100% - 4px);
		display: block;
		z-index: 1;
		opacity: 0.7;
		background: rgba(255,255,255,0);
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(35%, rgba(255,255,255,0)), color-stop(57%, rgba(90,90,90,0)), color-stop(69%, rgba(0,0,0,0.28)), color-stop(100%, rgba(0,0,0,1)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,0) 35%, rgba(90,90,90,0) 57%, rgba(0,0,0,0.28) 69%, rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );	
	}
	&.full{
		img{
			width: 100%;
		}
	}
	&.fh{
		.over-layer{
			height: 100%;
		}
	}
}
.nopad{
	padding:0px;
	padding-bottom: 15px;
}
#tour-content{
	display: inline-block;
	height: 100%;
	width: 100%;
	.tour-section{
		display: inline-block;
		width: 100%;
	}
	// itinerary UL
	#itinerary{
		ul{
		  list-style-image: url('list-style.png');
		  margin: 0;
		  padding: 0;
		  padding-left: 15px;
		  li{
		    margin: 10px 0px;
		    padding-left: 5px;
		  }
		}
	}
}
.keywords{
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	-ms-flex-direction: column;
	-webkit-flex-flow: row wrap; 
	flex-flow: row wrap; 
	display: -webkit-box;
	display: flex;
	align-items:stretch;
	.keyword{ 
		white-space: nowrap;
		background-color: #f1f1f1;
		transition: 0.2s;		
		padding: 7px 10px;
		margin: 3px;
		font-size: 16px;
		color:#666;
		.place-viewer{
			opacity: 0;
			visibility: hidden;
			position: absolute;
			z-index: -10;
			background-color: #fff;
			box-shadow: 0 1px 15px 1px rgba(0,0,0,0.3);
			overflow: hidden;
			transition: 0.4s opacity ease-in;
			width: 320px;
			max-width: 95%;
			color:#666;
			font-weight: 300;
			a{
				display: inline-block;
			}
			.col-md-12{
				padding: 0px;
			}
			margin-top: 8px;
			margin-left: -8px;
			h3{
				color:$red;
				padding-left: 5px;
				font-size: 16px;
				margin: 0;
			}
			.bc{
				background-color: #f7f7f7;
				span{
					color:#777;
					font-size: 13px;
					cursor: pointer;
					&:nth-child(1){
						padding-left: 5px;
					}
					i{
						font-size: 18px;
						color:#999;
						line-height: 21px;
						vertical-align: middle;
					}
					&:hover{
						color:$red;
					}
				}
			}
			p{
				white-space: pre-line;
				margin: 0;
				padding: 8px;
				span{
					color:$blue;
					cursor: pointer;
					font-size: 14px;
				}
			}
		}
		&:hover{
			transition: 0.4s opacity ease-out;
			background-color: $orange;
			color:#fff;
			.place-viewer{
				opacity: 1;
				visibility: visible;
				z-index: 100;
				overflow: hidden;
			}
		}
	}

}
.overview-rating{
	width: 31%;
	margin: .5%;
	padding: 0;
	background: #45484d;
	background: -moz-linear-gradient(top, #45484d 0%, #414141 100%);
	background: -webkit-linear-gradient(top, #45484d 0%,#414141 100%);
	background: linear-gradient(to bottom, #45484d 0%,#414141 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#414141',GradientType=0 );
	display: inline-block;
	.rating-bar{
		width: 0%;
		height: 19px;
		line-height: 19px;
		vertical-align: middle;
		text-indent: 3px;
		color:#fff;
		white-space: nowrap;
		font-size: 12px;
		transition:width 2.5s;
		&.r5{
			width: 100%;
			background-color: $darkgreen;
		}
		&.r4{
			width: 80%;
			background-color: $green;
		}
		&.r3{
			width: 60%;
			background-color: $lightgreen;
		}
		&.r2{
			width: 40%;
			background-color: $darkorange;
		}
		&.r1{
			width: 20%;
			background-color: $orange;
		}
	}
}
.gallery { 
	padding: 0;
	font-size: 0;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	-ms-flex-direction: column;
	-webkit-flex-flow: row wrap; 
	flex-flow: row wrap; 
	display: -webkit-box;
	display: flex;
  	div { 
		-webkit-box-flex: auto;
		-ms-flex: auto;
		flex: auto; 
		width: 200px; 
		margin: .2vw;
		img { 
			width: 100%; 
			height: auto;
			filter: brightness(80%);
			transition: 0.3s;
		}
		cursor: pointer;
		&:hover{
			img{			
				box-shadow: 1px 0px 10px 0 rgba(#000, 0.223);
				filter: brightness(100%);
			}
		}
	}
}
.galleries { 
	padding: 0;
	font-size: 0;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	-ms-flex-direction: column;
	-webkit-flex-flow: row wrap; 
	flex-flow: row wrap; 
	display: -webkit-box;
	display: flex;
	width: 90%;
  	div { 
		-webkit-box-flex: auto;
		-ms-flex: auto;
		flex: auto; 
		width: 200px; 
		margin: .2vw;
		img { 
			width: 100%; 
			height: auto;
			filter: brightness(80%);
			transition: 0.3s;
		}
		cursor: pointer;
		&:hover{
			img{			
				box-shadow: 1px 0px 10px 0 rgba(#000, 0.223);
				filter: brightness(100%);
			}
		}
	}
}
.2-cols{
	padding-right: 0px!important;
}
.why-us{
	padding: 10px 0;
	text-align: left;
	.col-md-9{
		height: 60px;
		text-align: center;
		a{
			line-height: 5px;
			vertical-align: middle;
			font-weight: 700;
			color:#666;
			i{
				font-size: 20px;
				line-height: 30px;
				vertical-align: middle;
			}
		}
	}
	h4{
		margin-top: 0;
		margin-bottom: 6px;
	}
}
.check-ul{
	margin:0;
	padding: 0;
	padding-left: 5px;
	width: 85%;
	float: left;
	list-style: none;
	text-align: left;
	li{
		font-size: 14px;
		vertical-align: middle;
		height: 25px;
		line-height: 25px;
		color:#666;
		i{
			line-height: 30px;
			height: 23px;
			vertical-align: text-bottom;
			font-size: 16px;
			font-weight: 700;
			display: inline-block;
		}
	}
	&.free{
		width: 100%!important;display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	-ms-flex-direction: column;
	-webkit-flex-flow: row wrap; 
	flex-flow: row wrap; 
	display: -webkit-box;
	display: flex;
		li{
			-webkit-box-flex: auto;
			-ms-flex: auto;
			flex: auto; 
			float: left!important;
			min-width: 50%!important;
			vertical-align: middle;
			line-height: 15px;
			height: 30px;
			padding: 6px 5px;
			margin: 0;
			&:before{
				content: '\25b6\00a0';
			}
		}
	}
}
.wx-padding{
	.col-md-3,.col-md-4,.col-md-5{
		padding:0;
	}
	div{
		&:nth-child(1){
			padding-right: 10px;
		}
		&:nth-child(2){
			padding-right: 10px;
			padding-left: 10px;
		}
		&:nth-last-child(1){
			padding-left: 10px;
		}
	}
}
.red-title{
	color:$orange;
}
.why-ul{
	margin:0;
	padding: 0;
	padding-left: 5px;
	display: block;
	width: 100%;
	float: left;
	list-style: none;
	text-align: center;
	li{
		float: left;
		min-width: 33%;
		min-height: 150px;
		display: inline-block;
		span{
			display: block;
		}
		color:#666;
		i{
			color:#999;
			font-size: 350%;
			font-weight: 700;
			display: block;
			margin-bottom: 20px;
		}
	}
}
.why-us-home{
	padding: 10px 0;
	text-align: left;
	display: inline-block;
	background-color: #ddd;
	vertical-align: middle;
	width: 100%;
	margin-bottom: 20px;
	.col-md-6{
		height: 40px;
		line-height: 40px;
		vertical-align: middle;
		text-align: center;
		a{
			line-height: 5px;
			vertical-align: middle;
			font-weight: 700;
			font-size: 24px;
			color:#666;
			transition: $color;
			i{
				font-size: 30px;
				line-height: 30px;
				vertical-align: middle;
			}
			&:hover{
				color:$darkgreen;
			}
		}
	}
}
.col-md-12{
	&.single{
		padding-right: 19px;
		padding-left: 19px;
	}
	&.w-padding{
		display: inline-block;
		width: 100%;
		.col-md-6{
			padding:0 20px;
		}
		.col-md-4{
			padding:0 20px;
		}
		.col-md-3{
			padding:0 20px;
		}
	}
	&.n-padding{
		padding: 0 0 10px 0;
		div{
			padding: 0px;
		}
	}
}
.text-bottom{
	vertical-align: text-bottom;
	padding-left: 3px;
	margin-top: 10px;
	li{
		label{
			font-size: 14px;
			&:before{
				margin-right: 2px;
				height: 20px;
				vertical-align: text-bottom;
				line-height: 30px;
			}
		}
	}
	&:hover{
		li{
			input{
				color:#333;
				line-height: 30px;
				vertical-align: middle;
				vertical-align: middle;
			}
			label{
				color:#333;
			}
		}
	}
}
.hr-line{
	display: inline-block;
    height: 30px;
    width: 100%;
}
.hr-line-sm{
	display: inline-block;
    height: 15px;
    width: 100%;
}
.hr-line-xs{
	display: inline-block;
    height: 6px;
    padding-bottom: 6px;
    width: 100%;
}
#booking-opts{
	width: 100%;
	display: block;
	background-color: #f7f7f7;
	overflow: hidden;
	p{
		padding: 0 10%;
	}
}
.bt-icon-left{
	line-height: 30px;
	vertical-align: middle;
}
.fg-title{
	text-transform: uppercase;
	color:#666;
	font-weight: 600;
}
.sm-title{
	font-weight: 600;
	height: 24px;
	font-size: 15px;
	line-height: 24px;
	vertical-align: middle;
	i{
		height: 24px;
		line-height: 24px;
		vertical-align: middle;
	}
}
.nmb{
	margin-bottom: 0px;
}
.npl{
	padding-left: 0px!important;
}
.npr{
	padding-right: 0px!important;
}
.smt{
	margin-top: 10px;
	margin-left: 2px;
	padding: 0px;
	label{
		font-weight: 300;
		color:#777;
	}
	&:hover{
		label{
			color:#555;
		}
	}
}
.nmt{
	margin-top: 0px!important;
}
.npb{
	padding-bottom: 0px!important;
}
.npt{
	padding-top: 0px!important;
}
.padbo{
	padding: 0 0 5px 0!important;
}

@media screen and (max-width: 425px) {
    .gallery { padding: 0; div { margin: 0 0 5px 0; } }
	.why-ul{
		li{
			min-width: 100%!important;
		}
	}
	.tour-cover{
		h1{
			font-size: 90%!important;
			margin-bottom: 4%;
		}
	}
  
}
@media screen and (max-width: 720px){
	#tour-content{
		// itinerary UL
		#itinerary{
			ul{
			  li{
			    padding-left: 20px;
			  }
			}
		}
	}
	.place-viewer{
		right:0;
		margin-right: 0;
	}
	.xs-npl{
	padding-left: 0px!important;
	}
	.xs-npr{
		padding-right: 0px!important;
	}
	.overview-rating{
		width: calc(100% - 10px);
		margin-left: 5px;
	}
	.why-us-home{
		.col-md-6{
			a{
				font-size: 18px;
				i{
					font-size: 22px;
				}
			}
		}
	}
	.check-ul{
		padding-left: 12px;
	}
	.tour-nav{
		ul{
			li{
				min-width: 33.33%!important;
				height: 30px;
				line-height: 30px;
				&:nth-child(1),&:nth-child(2),&:nth-child(3){
					border-bottom: 1px solid #dbdbdb;
				}
				a{
					font-size: 80%;
					i{
						font-size: 120%;
						display: none;
					}
				}
			}
		}
		&.sticky{
			padding: 0px!important;
			ul{
				li{
					a{
						i{
							display: block;
						}
					}
				}
			}
		}
	}
	h3{
		margin: 0 5px;
	}
	.tour-cover{
		padding: 0 5px!important;
		h1{
			font-size: 115%;
			margin-bottom: 4%;
			.rating{
				font-size: 70%;
			}
		}
		.over-layer{
			width: calc(100% - 8px);
			margin-left: 4px;
		}
		a{
			font-size: 12px;
		}
	}
	.tour-nav{
		padding: 0 5px!important;
	}
	.why-ul{
		li{
			min-width: 50%;
		}
	}
	#booking-opts{
		div{
			padding-bottom: 0px;
			padding-top: 0px;
			ul{
				&:nth-child(1){
					padding-bottom: 0px;
				}
				&:nth-child(2){
					padding-top: 0px;
				}
			}
		}
	}
}
@media screen and (max-width: 991px) {
	.wx-padding{
		.col-md-3,.col-md-4,.col-md-5{
			padding:0;
		}
		div{
			&:nth-child(1){
				padding-right: 0px;
			}
			&:nth-child(2){
				padding-right: 0px;
				padding-left: 0px;
			}
			&:nth-last-child(1){
				padding-left: 0px;
			}
		}
	}
}