@import 'variables';
.more-filters{
	margin-bottom: 5px;
	margin-right: 3px;
	cursor: pointer;
}
#filters{
	width: 100%;
	display: none;
	background-color: #f7f7f7;
	overflow: hidden;
	p{
		padding: 0 10%;
	}
}
.checkbox-ul{
	margin:0;
	padding: 5px;
	list-style: none;
	text-align: left;
	li{
		padding: 5px;
		vertical-align: middle;
		color:#666;
		label{
			i{
				font-size: 18px;
				line-height: 30px;
				vertical-align: middle;
				display: inline-block;
			}
		}
		i{
			line-height: 17px;
			height: 20px;
			vertical-align: middle;
			padding-right: 5px;
		}
	}
	&.filter{
		li{
			i{
				line-height: 23px;
				height: 23px;
				vertical-align: middle;
				padding-right: 0px;
			}
		}		
	}
	&.white{
		li{
			i{
				line-height: 23px;
				height: 23px;
				vertical-align: middle;
				padding-right: 0px;
			}
		}
	}
}