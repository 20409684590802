//Fonts
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  src: url('../fonts/Raleway-Regular.ttf');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$default-font: 'Raleway', sans-serif;

// Background Colors
$lightbg: #FFFFFF;
$bg: #F9F9F9;
$darkbg: #E0E0E0;

// Dark Colors
$dark: #6F6F6F;
$darker: #4A4A4A;

// Green Colors
$lightgreen: #6cca56;
$green: #42c923;
$darkgreen: #20ab00;

// Red Colors
$lightred: #cc2626;
$red: #a02323;
$darkred: #7a1818;

// Blue Colors
$lightblue: #488aec;
$blue: #3586ff;
$darkblue: #005be4;

// Orange Colors
$lightorange: #1ba9fa;
$orange: #1b98e0;
$darkorange: #2377a7;

// Yellow Colors
$lightyellow: #f3d21d;
$yellow: #e0c21b;
$darkyellow: #bfa61c;

// Violet Colors
$lightviolet: #D0B6D4;
$violet: #B16DC8;
$darkviolet: #541574;

// Brown Colors
$lightbrown: #97725E;
$brown: #64402C;
$darkbrown: #442818;

// Cyan Colors
$lightcyan: #2CBDE4;
$cyan: #019ECB;
$darkcyan: #0167A9;

// Custom Colors
$customone: #3D6372;
$customtwo: #50483D;
$customthree: #73A687;
$customfour: #A7937B;


//transition
$color: color 0.2s;

@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

//Legecy Directions
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}


@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


// Animations
@keyframes glow {
    0% {filter: brightness(100%) contrast(100%) grayscale(20%);box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.0);}
    50% {filter: brightness(130%) contrast(120%) grayscale(0%);box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.05);}
    100% {filter: brightness(100%) contrast(100%) grayscale(20%);box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.0);}
}

@keyframes glowD {
    0% {filter: brightness(100%) contrast(100%) grayscale(20%);box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.0);}
    50% {filter: brightness(130%) contrast(120%) grayscale(0%);box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);}
    100% {filter: brightness(100%) contrast(100%) grayscale(20%);box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.0);}
}

@keyframes borderS {
    0% {border-size:0px;}
    50% {border-size:3px;}
    100% {border-size:25px;}
}

//Dropdowns
.manage{
  list-style: none;
  margin: 0;
  padding: 0;
  >li{
    vertical-align: middle;
    line-height: 21px;
    font-size: 14px;
    >a{
      color:#444;
      >i{
        font-size: 18px;
        height: 21px;
        line-height: 21px;
        vertical-align: middle;
      }

    }
    >ul{
      list-style: none;
      display: none;
      height: auto;
      margin: 0;
      padding:5px;
      position: absolute;
      width: auto;
      right: 0;
      margin-right: 0px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.231);
      border:1px #dbdbdb solid;
      z-index: 50;
      >li{
        height: 29px;
        padding: 3px 7px;
        width: 100%;
        vertical-align: middle;
        line-height: 25px;
        font-size: 14px;
        display: block;
        border-bottom:1px #f7f7f7 solid;
        >a{
          color:#444;
          &.red{
            color:$red;
          }
          >i{
            font-size: 18px;
            height: 21px;
            line-height: 21px;
            vertical-align: middle;
          }

        }
        &:hover{
          background-color: #f9f9f9;
          a{
            color: $darkred;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

//form validation

//.narrow-panel
.panel{
  &.narrow-panel{
    margin: 10px 30px;
  }
}

//Typograph
small,h1,h2,h3,h4,h5,h6,i,span,b,a,label,p,article,address,ul,ol,li,div,strong,tr,td,th{
  &.c-green{
    color:$darkgreen!important;
  }
  &.c-orange{
    color:$orange!important;
  }
  &.c-red{
    color:$red!important;
  }
  &.c-blue{
    color:$blue!important;
  }
  &.c-cyan{
    color:$cyan!important;
  }
  &.c-violet{
    color:$violet!important;
  }
  &.c-yellow{
    color:$yellow!important;
  }
  &.c-dark{
    color:$dark!important;
  }
  &.c-white{
    color:#fff!important;
  }
  &.c-gray{
    color:#777!important;
    cursor: default!important;
    &.pt{
      padding-top: 10px!important; 
    }
  }
}

.bordred{
  border: 1px #dbdbdb solid;
  table{
    margin-bottom: 0px;
  }
}
.text-center{
  text-align: center;
}
h1{
  color:$darkred;
  font-size: 2.2em;
}
h2,h3,h4{
  color:$darkorange;
  &.regualr{
    font-weight: 400;
  }
}
p{
  color:#666;
  &.justify{
    text-align: justify;
    text-justify: distribute;
    img{
      margin-bottom: 5px;
    }
  }
  &.message-text{
    white-space: pre-line;
  }
  &.p-icon{
    line-height: 25px;
    i{
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
  }
}
i{
  &.green{
    color:$green
  }
}
a{
  &.inbox{
    color:#666;
    &:hover{
      color:$darkorange;
    }
    &.unread{
      font-weight: 600;
    }
  }
  &.bt-rm{
    color:#888;
    cursor: pointer;
    i{
      cursor: pointer;
    }
    transition:$color;
    &:hover{
      color:$red;
    }
  }
  &.bt-bm{
    color:#888;
    cursor: pointer;
    i{
      cursor: pointer;
    }
    transition:$color;
    &:hover{
      color:$blue;
    }
  }
  &.bt-gm{
    color:#888;
    cursor: pointer;
    i{
      cursor: pointer;
    }
    transition:$color;
    &:hover{
      color:$green;
    }
  }
  &.bt{
    cursor: pointer;
  }
  color:$red;
  text-decoration: none;
  &:hover{
    color:$darkorange;
  }
  &.ta{
    color:#777;
    img{
      max-height:25px;
    }
    :hover{
      background-color: #efefef;
      color:#1bad87;
    }
  }
}

h1,h2,h3,h4,h5,h6,i,span,label,p,article,address,ul,ol,li,div{
  cursor: default;
}
h1,h2,h3,h4,h5,h6{
  small{
    font-size: 70%;
    color:#b5b5b5;
  }
}
h3{
  margin-top: 10px;
  margin-bottom: 10px;
}

//Buttons
@mixin bt-style($color1,$color2) {
  background-color: $color1;
  border: 1px solid $color1;
  color:$color2;
  &:hover{
    background-color: transparent;
    color:$color1;
  }
  &:active{
    background-color: $color1;
    color:$color2;
  }
}
.clickable{
  cursor: pointer!important;
}
.bt{
  margin: 6px 0;
  padding: 6px 10px;
  text-decoration: none;
  transition: all 0.3s linear;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  &.bt-m-l{
    margin-left: 5px;
  }
  &.bt-icon{
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
  }
  &.bti{
    &.i-right{
      padding-right: 35px!important;
    }
    &.i-left{
      padding-left: 35px!important;
    }
  }
  i{
    cursor: pointer;
    padding: 12px 2px;
    top:0;
    &.bt-icon-right{
      position: absolute;
      right: 0;
      margin-right: 20px;
    }
    &.bt-icon-left{
      position: absolute;
      left: 0;
      margin-left: 20px;
    }
    &.i-free{
      padding: 0;
      font-size: 100%;
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
  }
  &.bt-lg{
    padding: 9px 11px;
    font-size: 18px;
    &.bf{
      font-size: 125%;
    }
    i{
      height: 25px;
      line-height: 24px;
      vertical-align: middle;
      font-size: 28px;
    }
  }
  &.bt-xl{
    padding: 11px 13px;
    font-size: 19px;
    letter-spacing: 1px;
  }
  &.bt-sm{
    padding: 5px 8px;
    font-size: 13px;
    .i{
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
  }
  &.bt-xs{
    padding: 3px 5px;
    font-size:12px;
    .i{
      line-height: 20px;
      vertical-align: middle;
      height: 20px;
    }
  }
  &.bt-block{
    display: block;
  }
  &.bt-default{
    border:1px solid #fff;
    background-color: #fff;
    color:#555;
    &:hover{
      border:1px solid #ababab;
      background-color: #fafafa;
      color:#333;
    }
    &:active{
      background-color: #dbdbdb;
      color:#333;
    }
  }
  &:hover{
      box-shadow: 1px 2px 3px 0 rgba(0,0,0, 0.175);
  }
  &.bt-green{
    @include bt-style($darkgreen, #fff);
  }
  &.bt-red{
    @include bt-style($red, #fff);
  }
  &.bt-blue{
    @include bt-style($blue, #fff);
  }
  &.bt-orange{
    @include bt-style($darkorange, #fff);
  }
  &.bt-yellow{
    @include bt-style($darkyellow, #fff);
  }
  &.bt-cyan{
    @include bt-style($cyan, #fff);
  }
  &.bt-violet{
    @include bt-style($violet, #fff);
  }
  &.bt-brown{
    @include bt-style($brown, #fff);
  }
}

.no-margain{
  margin: 0!important;
}

@media screen and (max-width: 992px){
  .bt{
    margin: 6px 0;
    padding: 6px 10px;
    text-decoration: none;
    transition: all 0.3s linear;
    text-align: center;
    display: inline-block;
    &.bti{
      &.i-right{
        padding-right: 35px!important;
      }
      &.i-left{
        padding-left: 35px!important;
      }
    }
    i{
      padding: 6px 2px;
      top:0;
      margin-top: 2px;
      &.bt-icon-right{
        position: absolute;
        right: 0;
        margin-right: 20px;
      }
      &.bt-icon-left{
        position: absolute;
        left: 0;
        margin-left: 20px;
      }
    }
  }
  //.narrow-panel
  .panel{
    &.narrow-panel{
      margin: 10px 0px;
      margin-top: 0px;
    }
  }
  .col-md-12{
    padding: 5;
  }
  p{
    padding: 0 10px;
  }
  .npb{
    padding-bottom: 0px!important;
  }
  .npt{
    padding-top: 0px!important;
  }
}

//validations
input,select,textarea{
  &.has-errors{
    border-color:$red!important;
  }
}
a{
  i{
    cursor: pointer;
  }
}